"use client";
import { StoreKey } from "@/const";
import { StorageData } from "@/utils/net/storage/StorageData";
import { useEffect, useState } from "react";
import { Alert, AlertHeader } from "@/components/Alert";
import { Button } from "@/components/Button";
import AlertBody from "@/components/Alert/AlertBody";
import { i18n } from "@/utils";

export const TimeoutDialog = (props: any) => {
  const { isShow = false, setIsShow } = props;
  // const [isShow, setIsShow] = useState(false);
  // const [state, setState] = useState<any>("");

  // const originalSetItem = localStorage.setItem;
  // localStorage.setItem = function (key, newValue) {
  //   const setItemEvent: any = new Event("setItemEvent");
  //   setItemEvent[key] = newValue;
  //   window.dispatchEvent(setItemEvent);
  //   originalSetItem.apply(this, [key, newValue]);
  // };

  // useEffect(() => {
  //   if (StorageData.local.get(StoreKey.TIMEOUT) === "true") {
  //     StorageData.local.set(StoreKey.TIMEOUT, false);
  //     setIsShow(true);
  //   }
  // }, [state]);

  const refresh = () => {
    location.reload();
    setIsShow?.(false);
  };

  // useEffect(() => {
  //   window.addEventListener("setItemEvent", function () {
  //     const data = StorageData.local.get(StoreKey.TIMEOUT);
  //     setState(data!);
  //   });
  //   return () => {
  //     window.removeEventListener("setItemEvent", function () {});
  //   };
  // }, []);

  return (
    <Alert open={isShow} onClose={() => setIsShow?.(false)} isLong={true} outsideClose={false}>
      <AlertHeader title={i18n.common("TIMEOUT")} />
      <AlertBody>
        <div className="flex min-h-[5rem] flex-col text-white items-center justify-center">
          <p className={"mb-[0.24rem] text-[0.33rem]"}>{i18n.common("REQUEST_TIMEOUT")}</p>
          <Button className="w-[2.75rem] block !my-[0.1rem] btn_animation" onClick={() => refresh()}>
            {i18n.common("REFRESH")}
          </Button>
        </div>
      </AlertBody>
    </Alert>
  );
};
