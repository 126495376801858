"use client";
import { Net, StoreKey } from "@/const";
import { useUserHook } from "@/hooks";
import { HttpClient, i18n } from "@/utils";
import { StorageData } from "@/utils/net/storage/StorageData";

import { Alert, AlertHeader } from "@/components/Alert";
import AlertBody from "@/components/Alert/AlertBody";
import { useAppSelector } from "@/redux/store";
import { useDispatch } from "react-redux";
import { AuthActions } from "@/redux/features/auth-slice";
import { EmailActions } from "@/redux/features/email-slice";
import { HallActions } from "@/redux/features/hall-slice";
import { Button } from "@/components/Button";
import { useMount } from "react-use";
import { EmailService } from "@/service";
import TouristModal from "@/components/TouristModal";
import { useEffect } from "react";

function Init() {
  const distPatch = useDispatch();
  const { login } = useUserHook();
  const show_profile_alert = useAppSelector((state) => state.authSlice.show_profile_alert);
  const report = useAppSelector((state) => state?.authSlice?.report);
  const mailInfoPush = useAppSelector((state) => state?.emailSlice?.mailInfoPush);
  const mail = useAppSelector((state) => state?.emailSlice?.mailData);
  const usr_basic_info = useAppSelector((state) => state?.authSlice?.basic_info);

  const onLogin = () => {
    login().then((r) => r);
  };

  // useEffect(() => {
  //   if (StorageData.local.get(StoreKey.INVITE_CODE) && report?.result === 0) {
  //     StorageData.local.set(StoreKey.INVITE_CODE, "");
  //   }
  // }, [report]);

  // 全局邮件推送
  useEffect(() => {
    if (mailInfoPush?.main_info?.mail_id) {
      const mailList = [...(mail || [])!, mailInfoPush?.main_info];
      StorageData.local.set(StoreKey.EMAIL_MIX_ID, mailInfoPush?.max_mail_id);
      EmailService.reqUnReadInfoReq(usr_basic_info?.uid!);
      distPatch(EmailActions?.setMailData(mailList));
      distPatch(EmailActions?.setMailInfoPush({}));
    }
  }, [mailInfoPush]);

  useMount(() => {
    distPatch(AuthActions.setTurntableAlert(undefined));
    // 清除首屏加载好的状态
    distPatch(HallActions.setFCP(true));
    // const isLogout = StorageData.local.get(StoreKey.USER_LOGOUT, false);
    HttpClient.baseUrl = Net.HTTP_URL!;
    setTimeout(() => {
      onLogin();
      console.log("init login");
    }, 0);
  });

  return (
    <>
      <Alert
        open={show_profile_alert?.net_error}
        outsideClose={false}
        onClose={() => distPatch(AuthActions.setNetError(false))}
      >
        <AlertHeader title={i18n.common("INCITAR")} />
        <AlertBody>
          <div className="flex items-center flex-col mt-[1rem] text-white">
            <p className="mb-[1.4rem] text-center">{i18n.common("NET_RECONNECT_FAILED")}</p>
            <div className={"flex justify-center items-center"}>
              <Button styleType={"red"} className={"w-[2.62rem]"} onClick={onLogin}>
                {i18n.translation("alert.net.reconnectTxt")}
              </Button>
            </div>
          </div>
        </AlertBody>
      </Alert>
      <TouristModal />
    </>
  );
}

export default Init;
