import { EventGlobalManager } from "@/utils/events";
import { AgentRank } from "@/const";
import { NetMsg } from "@/utils/net/INet";
import { client_proto } from "@/utils/net/shared/proto";
import { useDispatch } from "react-redux";
import { AgentRankActions } from "@/redux/features/agent-rank-slice";

export default function useAgentRankHooks() {
  const dispatch = useDispatch();
  function onListenSocket() {
    EventGlobalManager.on(AgentRank.Resp.SHOW_LIST, (res: NetMsg<client_proto.IAgentRankResponseRankShowListResp>) => {
      dispatch(AgentRankActions.setAgentRank(res.data));
    });

    EventGlobalManager.on(AgentRank.Resp.DATA_LIST, (res: NetMsg<client_proto.IAgentRankResponseRankListResp>) => {
      dispatch(AgentRankActions.setDataList(res.data));
    });

    EventGlobalManager.on(AgentRank.Resp.GET_DETAILS, (res: NetMsg<client_proto.IAgentRankGetUserDetailsResp>) => {
      dispatch(AgentRankActions.setGetUserDetailsResp(res.data));
    });

    EventGlobalManager.on(AgentRank.Resp.REWARD_LIST, (res: NetMsg<client_proto.IAgentRankGetUserDetailsResp>) => {
      dispatch(AgentRankActions.setRewardList(res.data));
    });

    EventGlobalManager.on(AgentRank.Resp.GET_REPORT, (res: NetMsg<client_proto.IAgentRankGetUserDetailsResp>) => {
      dispatch(AgentRankActions.setRankListReport(res.data));
    });
  }

  return {
    onListenSocket,
  };
}
