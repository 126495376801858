import { MyBound } from "@/const";

import { MyBonusActions } from "@/redux/features/my-bonus-slice";
import { EventGlobalManager } from "@/utils/events";
import { useDispatch } from "react-redux";

export default function useMyBonusHooks() {
  const dispatch = useDispatch();

  const onListenSocket = () => {
    EventGlobalManager.on(MyBound.Resp.vipBetReadte, (rsp: any) => {
      console.log("vipbetredate.QuerylaimRewardResp........success");
      const { data } = rsp;
      dispatch(MyBonusActions.setVipBetReadte(data));
    });

    EventGlobalManager.on(MyBound.Resp.vipBetReadteReward, (rsp: any) => {
      console.log("vipbetredate.vipBetReadteReward........success");
      const { data } = rsp;
      dispatch(MyBonusActions.setVipBetReadteReward(data));
    });
  };

  return {
    onListenSocket,
  };
}
