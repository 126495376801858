import { Agent } from "@/const";
import { AgentActions } from "@/redux/features/agent-slice";
import { EventGlobalManager } from "@/utils/events";
import { useDispatch } from "react-redux";

export default function useAgentHooks() {
  const dispatch = useDispatch();

  const onListenSocket = () => {
    EventGlobalManager.on(`${Agent.Req.INFO}`, (rsp: any) => {
      const { data } = rsp;
      dispatch(AgentActions.setInfo(data));
    });
    EventGlobalManager.on(`${Agent.Req.WITHDRAW_LIST}`, (rsp: any) => {
      const { data } = rsp;
      dispatch(AgentActions.setWithrawList(data));
    });
    EventGlobalManager.on(`${Agent.Req.WITHDRAW}`, (rsp: any) => {
      const { data } = rsp;
      dispatch(AgentActions.setWithraw(data));
    });
    EventGlobalManager.on(`${Agent.Resp.TEAM_INFO}`, (rsp: any) => {
      const { data } = rsp;
      dispatch(AgentActions.setTeamInfo(data));
    });
    EventGlobalManager.on(`${Agent.Req.USER_SUB_INFO}`, (rsp: any) => {
      const { data } = rsp;
      dispatch(AgentActions.setUserSubInfo(data));
    });
    EventGlobalManager.on(`${Agent.Req.TEAM_LIST}`, (rsp: any) => {
      const { data } = rsp;
      dispatch(AgentActions.setTeamList(data));
    });
    EventGlobalManager.on(`${Agent.Req.COMMISSION_LIST}`, (rsp: any) => {
      const { data } = rsp;
      dispatch(AgentActions.setCommssionList(data));
    });
    EventGlobalManager.on(`${Agent.Req.INVITE}`, (rsp: any) => {
      const { data } = rsp;
      dispatch(AgentActions.setInvite(data));
    });
    EventGlobalManager.on(`${Agent.Req.CONFIG}`, (rsp: any) => {
      const { data } = rsp;
      dispatch(AgentActions.setConfig(data));
    });
    EventGlobalManager.on(`${Agent.Req.INVITE_REWARD}`, (rsp: any) => {
      const { data } = rsp;
      dispatch(AgentActions.setInviteReward(data));
    });
    EventGlobalManager.on(`${Agent.Req.CHECK_INVITESTATUS}`, (rsp: any) => {
      const { data } = rsp;
      dispatch(AgentActions.setCheckInviteStatus(data));
    });
  };

  return {
    onListenSocket,
  };
}
