import { Email } from "@/const";
import { EmailActions } from "@/redux/features/email-slice";
import { EventGlobalManager } from "@/utils/events";
import { useDispatch } from "react-redux";

function useEmailHooks() {
  const dispatch = useDispatch();

  function onListenSocket() {
    EventGlobalManager.on(Email.Resp.UnReadInfo, (rsp: any) => {
      const { data } = rsp;
      dispatch(EmailActions.setUnReadInfo(data));
    });

    EventGlobalManager.on(Email.Resp.MailList, (rsp: any) => {
      const { data } = rsp;
      dispatch(EmailActions.setMailList(data));
    });

    EventGlobalManager.on(Email.Resp.MailDelete, (rsp: any) => {
      const { data } = rsp;
      dispatch(EmailActions.setMailDelete(data));
    });

    EventGlobalManager.on(Email.Resp.MailReceive, (rsp: any) => {
      const { data } = rsp;
      dispatch(EmailActions.setMailReceive(data));
    });

    EventGlobalManager.on(Email.Resp.MailMarkRead, (rsp: any) => {
      const { data } = rsp;
      dispatch(EmailActions.setMailMarkRead(data));
    });

    EventGlobalManager.on(Email.Push.MailInfoPush, (rsp: any) => {
      const { data } = rsp;
      dispatch(EmailActions.setMailInfoPush(data));
    });
  }

  return {
    onListenSocket,
  };
}

export default useEmailHooks;
