import { ThirdGame } from "@/const";
import { TeamActions } from "@/redux/features/team-slice";
import { EventGlobalManager } from "@/utils/events";
import { useDispatch } from "react-redux";
import { get } from "lodash-es";
import { NetMsg } from "@/utils/net/INet";
import { bridge_proxy_proto, client_proto, game_manage_proto, tgame_proto } from "@/utils/net/shared/proto";

// 大厅游戏列表，三方游戏

// CmdToPbName[bridge_proxy_proto.RBRIDGE_PROXY_SUB_MSG_ID.BRIDGE_PROXY_KICKOUT_PUSH] =
//   ThirdGame.Resp.BRIDGE_PROXY_PUSH_KICKOUT;
// CmdToPbName[tgame_proto.TGAME_SUB_MSG_ID.TGAME_KICKOUT_PUSH] = ThirdGame.Resp.TGAME_SUB_GAME_PUSH_KICKOUT;

export default function useThirdGameHooks() {
  const dispatch = useDispatch();

  function onListenSocket() {
    EventGlobalManager.on(ThirdGame.Resp.BRIDGE_SUB_GAME, (rsp: NetMsg<bridge_proxy_proto.ILoginResp>) => {
      const data = get(rsp, "data");
      dispatch(TeamActions.setBridgeLoginResp(data));
    });

    EventGlobalManager.on(ThirdGame.Resp.TGAME_SUB_GAME, (rsp: NetMsg<tgame_proto.ILoginResp>) => {
      const data = get(rsp, "data");
      dispatch(TeamActions.setTGameLoginResp(data));
    });

    EventGlobalManager.on(ThirdGame.Resp.TGAME_SUB_GAME_LOGOUT, (rsp: NetMsg<tgame_proto.ILogoutResp>) => {
      const data = get(rsp, "data");
      dispatch(TeamActions.setTGameLogoutResp(data));
    });

    EventGlobalManager.on(ThirdGame.Resp.GAME_HALL_DETAIL, (rsp: NetMsg<game_manage_proto.IGameHallDetailResp>) => {
      const data = get(rsp, "data");
      dispatch(TeamActions.setGameHallDetailResp(data));
    });

    EventGlobalManager.on(ThirdGame.Resp.GAME_GROUP_DETAIL, (rsp: NetMsg<game_manage_proto.IGameGroupDetailResp>) => {
      const data = get(rsp, "data");
      dispatch(TeamActions.setGameGroupDetailResp(data));
    });

    EventGlobalManager.on(ThirdGame.Resp.GAME_GROUP_V1, (rsp: any) => {
      const { data } = rsp;
      if (data) {
        const jsonStr = get(data, "data");
        const json = JSON.parse(jsonStr);
        dispatch(TeamActions.setMultiLanguageGameGroupV1(json));
      }
    });

    EventGlobalManager.on(
      ThirdGame.Resp.GAME_GROUP_V1_DETAIL,
      (rsp: NetMsg<game_manage_proto.IGameGroupDetailV1Req>) => {
        const { data } = rsp;
        dispatch(TeamActions.setGameGroupDetailV1(data));
      },
    );

    EventGlobalManager.on(
      ThirdGame.Resp.GAME_GROUP_V2_DETAIL,
      (rsp: NetMsg<game_manage_proto.IGameGroupDetailV2Resp>) => {
        const { data } = rsp;
        dispatch(TeamActions.setGameGroupDetailV2(data));
      },
    );

    EventGlobalManager.on(ThirdGame.Resp.BRIDGE_PROXY_PUSH_KICKOUT, (rsp: NetMsg<bridge_proxy_proto.IKickOutPush>) => {
      const data = get(rsp, "data");
      dispatch(TeamActions.setBridgeProxyKickOutResp(data));
    });

    EventGlobalManager.on(ThirdGame.Resp.TGAME_SUB_GAME_PUSH_KICKOUT, (rsp: NetMsg<tgame_proto.IKickOutPush>) => {
      const data = get(rsp, "data");
      dispatch(TeamActions.setTGameKickOutResp(data));
    });

    EventGlobalManager.on(ThirdGame.Resp.TOP_BANNER_PUSH, (rsp: NetMsg<client_proto.IGameBannerUserPush>) => {
      const data = get(rsp, "data");
      dispatch(TeamActions.setGameBannerUserPush(data));
    });
  }

  return {
    onListenSocket,
  };
}
