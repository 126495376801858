import "./index.scss";

interface ProgressProps {
  children?: React.ReactNode;
  progress?: number;
}

function Progress(props: ProgressProps) {
  const { progress, children } = props;
  return (
    <div className="progress">
      <div className="progress-bar" style={{ width: `${progress}%` }} />
      {children}
    </div>
  );
}

export default Progress;
