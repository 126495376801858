"use client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "@/redux/store";
import { PROP_FROM, PropertyType, assetsKey } from "./type";
import { useDispatch } from "react-redux";
import { AssetService } from "@/service";
import { usePathname } from "next/navigation";
import { AssetResp } from "@/redux/features/auth-slice";
import { GoodsActions } from "@/redux/features/goods-slice";
import { GiftPop } from "@/widget/GiftPop/index";

export const Goods = () => {
  const name = usePathname();
  const dispatch = useDispatch();
  const propPush = useAppSelector((state) => state.goodsSlice.propPush);
  const usr_basic_info = useAppSelector((state) => state.authSlice.basic_info);
  const asset: any = useAppSelector((state) => state.authSlice.asset);
  const popKey = useRef<number | null>(null);
  const [popList, setPopList] = useState<any>([]);

  const [isRewardModalOpen, setIsRewardModalOpen] = useState(false);

  const checkShowNotice = (propfrom: any) => {
    const arr = [PROP_FROM.SPIN_WITHDRAW, PROP_FROM.WALLET_RECHARGE, PROP_FROM.WALLET_WITHDRAW, PROP_FROM.COMMISSIONS];
    if (arr?.includes(Number(propfrom))) {
      return false;
    }
    if (Number(propfrom) === PROP_FROM.INVITE_ATIVITE) {
      return name !== "/gameList/game";
    }
    return true;
  };

  const checkIsMoney = (id: number) => Object?.values(PropertyType)?.includes(id);

  const handleCloseModal = () => {
    setIsRewardModalOpen(false);
    const data = popList?.filter((val: any) => val?.propfrom !== popKey.current) || [];
    setPopList(data);
    popKey.current = null;
    if (data?.length === 0) {
      dispatch(GoodsActions.setPropPush({}));
    }
  };

  useEffect(() => {
    if (popList?.length === 0 || popList?.[0]?.propfrom === popKey.current) {
      return;
    }
    const data = popList?.[0];
    popKey.current = data?.propfrom;
    if (data?.propfrom === PROP_FROM.FRESH) {
      AssetService.reqAsset(usr_basic_info?.uid!);
      handleCloseModal();
      return;
    }
    if (data?.reward?.length! > 0) {
      let reward = data?.reward;
      reward?.forEach((val: any) => {
        if (checkIsMoney(val?.id)) {
          const key = assetsKey[val?.id!];
          const oldMoney = asset?.[key];
          const newMoney = Number(oldMoney) + Number(val?.num);
          const data = {
            ...asset,
            [key]: newMoney,
          };
          dispatch(AssetResp(data));
        }
      });
      if (checkShowNotice(data?.propfrom)) {
        setIsRewardModalOpen(true);
      } else {
        handleCloseModal();
      }
    }
  }, [popList]);

  useEffect(() => {
    if (!propPush || !propPush.reward) {
      return;
    }
    setPopList([...popList, propPush]);
  }, [propPush]);
  return (
    <GiftPop
      isOpen={isRewardModalOpen}
      onClose={handleCloseModal}
      rewardList={propPush?.reward}
      params={propPush?.params}
    />
  );
};
