import { VipData } from "@/redux/features/vip-slice";
import { StorageData } from "@/utils/net/storage/StorageData";

export type VipItem = {
  name?: string;
  vipLv?: number;
  reward_id: number;
  type: number;
  bonus: number;
  next_time?: number;
  rewardType: RewardType;
  reward_money?: number;
  rank?: number;
  state?: number;
  reward?: number;
};
export enum RewardType {
  upgrade = 1,
  week = 2,
  rank = 3,
}
export const VIP_REWARD_ID = {
  VIP_REWARD_NULL: 0,
  VIP_REWARD_UPGRADE: 1,
  VIP_REWARD_WEEKLY: 2,
};
//获取可领取奖励
export const getNowVipReward = (vip_config_info: Partial<VipData>) => {
  const upgradeList: VipItem[] = [];
  const weekList: VipItem[] = [];
  vip_config_info.vip_list?.forEach((vipList_item) => {
    let take_reward = vipList_item.take_reward;
    let reward = vipList_item.reward;
    let vipId = vipList_item.vip_id;
    take_reward?.forEach((takeReward_item) => {
      reward?.forEach((reward_item) => {
        let jsonReward_item = JSON.parse(reward_item);
        //next_time大于0的话也要进行展示
        const realRewardId =
          typeof takeReward_item.reward_id == "number"
            ? takeReward_item.reward_id
            : VIP_REWARD_ID[takeReward_item.reward_id as unknown as keyof typeof VIP_REWARD_ID];
        if (realRewardId == jsonReward_item.id && vipId > 0) {
          if (jsonReward_item.type == 1) {
            upgradeList.push({
              vipLv: vipId,
              reward_id: jsonReward_item.id,
              type: jsonReward_item.type,
              bonus: jsonReward_item.bonus,
              next_time: takeReward_item.next_time,
              rewardType: RewardType.upgrade,
            });
          } else if (jsonReward_item.type == 2) {
            if (vipId > 1) {
              weekList.push({
                vipLv: vipId,
                reward_id: jsonReward_item.id,
                type: jsonReward_item.type,
                bonus: jsonReward_item.bonus,
                next_time: takeReward_item.next_time,
                rewardType: RewardType.week,
              });
            }
          }
        }
      });
    });
  });
  return { upgradeList, weekList };
};
