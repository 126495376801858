import { Rank } from "@/const/rank";
import { RankActions } from "@/redux/features/rank-slice";
import { EventGlobalManager } from "@/utils/events";
import { NetMsg } from "@/utils/net/INet";
import { client_proto } from "@/utils/net/shared/proto";
import { useDispatch } from "react-redux";
import { get } from "lodash-es";

function useRankHooks() {
  const dispatch = useDispatch();
  function onListenSocket() {
    EventGlobalManager.on(Rank.Resp.SHOW_LIST, (rsp: NetMsg<client_proto.IResponseRankShowListResp>) => {
      const { data } = rsp;
      dispatch(RankActions.setShowList(data));
    });

    EventGlobalManager.on(Rank.Resp.DATA_LIST, (rsp: NetMsg<client_proto.IResponseRankListResp>) => {
      const { data } = rsp;
      if (data?.is_history) {
        dispatch(RankActions.setHistoryDataList(data));
      } else {
        dispatch(RankActions.setDataList(data));
      }
    });

    EventGlobalManager.on(Rank.Resp.JACKPOT, (rsp: NetMsg<client_proto.IResponseRankJackpotResp>) => {
      const { data } = rsp;
      dispatch(RankActions.setJackpot(data));
    });

    EventGlobalManager.on(Rank.Resp.REWARD_LIST, (rsp: NetMsg<client_proto.IResponseRewardListResp>) => {
      const { data } = rsp;
      dispatch(RankActions.setRewardList(data));
    });

    EventGlobalManager.on(Rank.Resp.GET_REWARD, (rsp: NetMsg<client_proto.IResponseGetRewardResp>) => {
      const data = get(rsp, "data");
      dispatch(RankActions.setGetReward(data));
    });

    EventGlobalManager.on(Rank.Resp.GAME_LIST, (rsp: NetMsg<client_proto.IResponseJoinGameListResp>) => {
      const data = get(rsp, "data");
      dispatch(RankActions.setGameList(data));
    });

    EventGlobalManager.on(Rank.Resp.GET_REPORT, (rsp: NetMsg<client_proto.IRankListReportResp>) => {
      const data = get(rsp, "data");
      dispatch(RankActions.setGetReport(data));
    });
  }

  return {
    onListenSocket,
  };
}

export default useRankHooks;
