import { createContext } from "react";

type SettingContextType = {
  settings: {
    language: string;
    showActivityButton?: boolean;
  };
  setSettings?: (patch: SettingContextType["settings"]) => void;
};

const SettingContext = createContext<SettingContextType>({
  settings: {
    language: "pt",
  },
});

export const SettingProvider = SettingContext.Provider;
export default SettingContext;
