import { WheelActions } from "@/redux/features/wheel.slice";
import { clientProtoPrefix } from "@/utils";
import { EventGlobalManager } from "@/utils/events";
import { useDispatch } from "react-redux";

const useWheelHooks = () => {
  const dispatch = useDispatch();
  const onListenSocket = () => {
    EventGlobalManager.on(WHEEL.Res.INFO, (res: any) => {
      dispatch(WheelActions.setWheelData(res.data));
    });
    EventGlobalManager.on(WHEEL.Res.SPIN, (res: any) => {});
    EventGlobalManager.on(WHEEL.Res.WITHDRAW, (res: any) => {});
    EventGlobalManager.on(WHEEL.Res.RECORD, (res: any) => {});
    EventGlobalManager.on(WHEEL.Res.ADD, (res: any) => {});
    EventGlobalManager.on(WHEEL.Res.CURDATA, (res: any) => {
      dispatch(WheelActions.setCurData(res.data));
    });
    EventGlobalManager.on(WHEEL.Res.POSTER, (res: any) => {});
  };

  return {
    onListenSocket,
  };
};

export namespace WHEEL {
  export const Req = {
    INFO: clientProtoPrefix("SpinInfoReq"),
    SPIN: clientProtoPrefix("SpinReq"),
    WITHDRAW: clientProtoPrefix("SpinWithdrawReq"),
    RECORD: clientProtoPrefix("SpinOrderLogReq"),
    CURDATA: clientProtoPrefix("SpinGetCurDataReq"),
    POSTER: clientProtoPrefix("SpinPosterReq"),
  };
  export const Res = {
    INFO: clientProtoPrefix("SpinInfoResp"),
    SPIN: clientProtoPrefix("SpinResp"),
    WITHDRAW: clientProtoPrefix("SpinWithdrawResp"),
    RECORD: clientProtoPrefix("SpinOrderLogResp"),
    ADD: clientProtoPrefix("SpinRecordAddPush"),
    CURDATA: clientProtoPrefix("SpinGetCurDataResp"),
    POSTER: clientProtoPrefix("SpinPosterResp"),
  };
}

export default useWheelHooks;
