import { getNowVipReward } from "@/app/home/Reward/helper";
import { StoreKey } from "@/const";
import { useAppSelector } from "@/redux/store";
import { StorageData } from "@/utils/net/storage/StorageData";

function useAcNum() {
  const use_basic_info = useAppSelector((state) => state.authSlice.basic_info);
  const curData = useAppSelector((state) => state.spinSlice.curData);
  const vip_config_info = useAppSelector((state) => state.vipSlice.vipConfigInfo);
  const rewardListResp = useAppSelector((state) => state.rankSlice?.rewardList);
  const vipBetReadte = useAppSelector((state) => state.myBonusSlice.vipBetReadte);
  const agentRewardList = useAppSelector((state) => state.agentRankSlice.rewardListResp);

  const rankNum = (): number => {
    if (!rewardListResp) {
      return 0;
    }
    let rank_config = rewardListResp?.reward_list;
    let rankNum = 0;
    if (rank_config && rank_config.length > 0) {
      for (let i = 0; i < rank_config.length; i++) {
        let rankItem = rank_config[i];
        if (rankItem.state == 1) {
          rankNum++;
        }
      }
      return rankNum;
    }
    return 0;
  };

  const vipNum = (): number => {
    //vip
    // 升级奖励 有升级奖励可领取
    let num = 0;
    const { weekList, upgradeList } = getNowVipReward(vip_config_info);
    if (upgradeList && upgradeList.length > 0) {
      num++;
    }
    //周奖励
    let weekIsCount = true;
    for (let i = 0; i < weekList.length; i++) {
      const element = weekList[i];
      if (element.next_time! > 0) {
        weekIsCount = false;
      }
    }
    if (weekList && weekList.length > 0 && weekIsCount) {
      num++;
    }
    return num;
  };

  // 代理排行版的数量
  const getAgentRankNum = () => {
    let total = 0;
    agentRewardList?.reward_list?.forEach((agentRankItem) => {
      if (agentRankItem.state === 1) {
        total++;
      }
    });
    return total;
  };
  //计算活动数量
  const refreshAcNum = () => {
    let acNum = 0;
    //首充
    let firstDeposit_config = ((use_basic_info || {}) as any).rechargecount;
    !firstDeposit_config && StorageData.local.get(StoreKey.FIRST_CHARGE) === "true" && acNum++;
    // //累充TODOGTY
    // let totalDeposit_config = Cache.Activity.getActivityItemByActivityType(ActivityType.ACCUMULATE_RECHARGE)
    // totalDeposit_config && this.acNum++

    //转盘
    let wheel_tar = curData.target;
    let wheel_amount = curData.amount;
    if (wheel_tar && wheel_tar <= wheel_amount) {
      acNum++;
    }

    //时间倒计时不计数
    if (vipNum() > 0) {
      acNum += vipNum();
    }

    //排行榜
    const rankResult = rankNum();
    if (rankResult > 0) {
      acNum += rankResult;
    }
    // vip下注奖励
    if (vipBetReadte.sign == 1) {
      acNum += 1;
    }

    const agentRankNum = getAgentRankNum();

    if (agentRankNum > 0) {
      acNum += agentRankNum;
    }

    return acNum;
  };

  return {
    refreshAcNum,
    rankNum,
    vipNum,
  };
}

export default useAcNum;
