import cc_dt_tb_1_1 from "@/assets/home/cc_dt_tb_1.1.inline.png";
import cc_dt_tb_2_1 from "@/assets/home/cc_dt_tb_2.1.inline.png";
import topone_cc_dt_tb_2_1 from "@/assets/home/topone_cc_dt_tb_2.1.inline.png";

import cc_dt_tb_3_1 from "@/assets/home/cc_dt_tb_3.1.inline.png";
import cc_dt_tb_4_1 from "@/assets/home/cc_dt_tb_4.1.inline.png";
import cc_dt_tb_1 from "@/assets/home/cc_dt_tb_1.inline.png";
import cc_dt_tb_2 from "@/assets/home/cc_dt_tb_2.inline.png";
import cc_dt_tb_3 from "@/assets/home/cc_dt_tb_3.inline.png";
import cc_dt_tb_4 from "@/assets/home/cc_dt_tb_4.inline.png";
import en_cc_dt_tb_4 from "@/assets/home/en_cc_dt_tb_4.inline.png";

export const pngList = {
  cc_dt_tb_1_1,
  cc_dt_tb_2_1,
  topone_cc_dt_tb_2_1,
  cc_dt_tb_3_1,
  cc_dt_tb_4_1,
  cc_dt_tb_1,
  cc_dt_tb_2,
  cc_dt_tb_3,
  cc_dt_tb_4,
  en_cc_dt_tb_4,
};
