import { Renver } from "@/const";
import { EventGlobalManager } from "@/utils/events";
import { NetMsg } from "@/utils/net/INet";
import { transactions } from "@/utils/net/shared/proto";
import { useDispatch } from "react-redux";
import { get } from "lodash-es";
import { RenverActions } from "@/redux/features/renver-slice";

export default function useRenverHooks() {
  const dispatch = useDispatch();

  function onListenSocket() {
    EventGlobalManager.on(Renver.Resp.INFO, (rsp: NetMsg<transactions.ITransactionsResp>) => {
      const data = get(rsp, "data");
      dispatch(RenverActions.setInfo(data));
    });
  }

  return {
    onListenSocket,
  };
}
