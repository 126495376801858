"use client";
import AlertBody from "@/components/Alert/AlertBody";
import AvatarAlert from "./AvatarAlert";
import BindPhoneCompleteAlert from "./BindPhoneCompleteAlert";
import classNames from "classnames";
import DefinirSenhaAlert from "./DefinirSenhaAlert";
import GendersAlert from "./GendersAlert";
import Logout from "./Logout";
import ProfileMenu from "./ProfileMenu";
import ProfileWallet from "./ProfileWallet";
import SettingsAlert from "./SettingsAlert";
import ChangePassword from "./ChangePasswordAlert";
import { Alert, AlertHeader } from "@/components/Alert";
import { AssetService, VipService, WalletService } from "@/service";
import { AuthActions } from "@/redux/features/auth-slice";
import { cdnPrefix, getAvatarUrl, i18n, prefixUtils } from "@/utils";
import { formatNumber } from "accounting";
import { Progress } from "@/components/Progress";
import { useAppSelector } from "@/redux/store";
import { useDispatch } from "react-redux";
import { useContext, useEffect, useMemo, useRef } from "react";
import { useUpdateEffect } from "react-use";
import copyTextToClipboard from "@uiw/copy-to-clipboard";
import { Vip } from "@/components/Vip";
import toast from "../Toast";
import Link from "next/link";
import Img from "@/components/Img";
import { useLoginInterceptor } from "@/components/RouterGuard/login.guard";
import { LazyImage } from "@/components/LazyloadImage";

import ICON_G_KH from "@/assets/profile/g_kh_icon.inline.png";
import ICON_CC_TXK from "@/assets/profile/cc_txk.inline.png";
import ICON_G_XGTX from "@/assets/profile/g_xgtx_icon.inline.png";
import ICON_FZ from "@/assets/common/fz.inline.png";
import ICON_NV from "@/assets/profile/g_nv.inline.png";
import ICON_NAN from "@/assets/profile/g_nan.inline.png";
import ICON_SJ from "@/assets/profile/seguranca/sj.inline.png";
import ICON_SOU from "@/assets/profile/seguranca/suo.inline.png";
import ICON_ST from "@/assets/profile/seguranca/st.inline.png";
import ICON_TZHUAN from "@/assets/profile/g_tzhuan_icon.inline.png";
// import ICON_G_KP from "@/assets/profile/g_kp.inline.png";
import ICON_G_ZD from "@/assets/profile/g_zd_icon.inline.png";
import BG_ASIDER from "@/assets/common/asider_bg.inline.png";

import "./index.scss";
import SettingContext from "@/service/setting";
import ProfileAppDownload from "./ProfileAppDownload";

const profile = prefixUtils("profile");
const profileContainer = prefixUtils("profile-container");
const profileContainerPerfile = prefixUtils("profile-container-perfile");
const profileContainerInfo = prefixUtils("profile-container-info");
const profileVip = prefixUtils("profile-container-vip");

function ProfileAside() {
  const dispatch = useDispatch();
  const show_profile_alert = useAppSelector((state) => state.authSlice.show_profile_alert);
  const userBaseInfo = useAppSelector((state) => state.authSlice.basic_info);
  const user_vip_resp = useAppSelector((state) => state.vipSlice.user_vip_resp);
  const asset = useAppSelector((state) => state.authSlice.asset);
  const withdrawInfo = useAppSelector((state) => state.wallet.withdrawInfo);
  const isFCP = useAppSelector((state) => state.hallSlice.isFCP);
  const profileRef = useRef<HTMLElement>(null);
  const closeRef = useRef<HTMLDivElement>(null);

  const { isGuest } = useLoginInterceptor();
  const { settings } = useContext(SettingContext);

  useUpdateEffect(() => {
    if (userBaseInfo?.uid && isFCP) {
      VipService.getCurVipInfo(userBaseInfo.uid!);
      AssetService.reqAsset(userBaseInfo.uid!);
      WalletService.reqWithdrawInfo();
    }
  }, [userBaseInfo, isFCP]);

  const curVipInfo = useMemo(() => {
    return {
      curLevel: user_vip_resp?.level || 0,
      curExp: user_vip_resp?.score / 1000,
      maxExp: user_vip_resp?.max_score / 1000,
    };
  }, [user_vip_resp]);

  const money = useMemo(() => {
    const amount = asset?.amount || 0;
    const coin = asset?.coin || 0;
    return formatNumber((amount! + coin!) / 1000);
  }, [asset?.amount, asset?.coin]);

  const mobile = useMemo(() => {
    const len = userBaseInfo?.user_mobile?.length!;
    const suffix = len - 3;

    const prefix = userBaseInfo?.user_mobile?.substring(0, 3);
    const suffixStr = userBaseInfo?.user_mobile?.substring(suffix, len);

    return `${prefix}****${suffixStr}`;
  }, [userBaseInfo?.user_mobile]);

  const isShowBindPsaaword = useMemo(() => {
    return withdrawInfo?.bank_cpf !== "" && settings?.language === "pt";
  }, [withdrawInfo, settings]);

  useEffect(() => {
    const hiddenAside = () => {
      const newAlerts = { ...show_profile_alert };
      delete newAlerts?.aside;
      const alters = Object.values(newAlerts);
      const isShow = alters.some((item) => item);
      if (!isShow) {
        dispatch(AuthActions.setAsideAlert(false));
      }
    };
    const bodyClick = (event: MouseEvent) => {
      if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
        hiddenAside();
      }
    };

    document.body.addEventListener("click", bodyClick);
    window.addEventListener("beforeunload", hiddenAside);

    return () => {
      document.body.removeEventListener("click", bodyClick);
      window.removeEventListener("beforeunload", hiddenAside);
    };
  }, [dispatch, show_profile_alert]);

  useUpdateEffect(() => {
    if (profileRef.current && show_profile_alert?.aside) {
      const { width } = profileRef.current?.getBoundingClientRect()!;
      if (closeRef.current) {
        closeRef.current.style.right = `${width - 3}px`;
      }
    }
  }, [show_profile_alert?.aside]);

  return (
    <>
      {show_profile_alert?.aside && (
        <aside
          className={classNames(profile(""), "transition transform duration-300 ease-in-out", {
            "translate-x-full": !show_profile_alert?.aside,
          })}
        >
          <section ref={profileRef} className={profileContainer("")}>
            <div className={profileContainerPerfile("")}>{i18n.profile("LYSETTING_30")}</div>
            <div className={profileContainerInfo("")}>
              <div
                className={profileContainerInfo("-avatar")}
                onClick={() => dispatch(AuthActions.setAvatarAlert(true))}
              >
                <div className={profileContainerInfo("-avatar-photo")}>
                  <Img
                    className={profileContainerInfo("-avatar-photo-image")}
                    // src={cdnPrefix(`/profile/g_tx0${userBaseInfo?.user_avatar}.jpg`)}
                    src={getAvatarUrl(userBaseInfo?.user_avatar)}
                    alt="Avatar"
                    width={120}
                    height={120}
                  />
                </div>
                <div className={profileContainerInfo("-avatar-circle")}>
                  <LazyImage height={"1.3rem"} src={ICON_CC_TXK as unknown as string} alt="cc_txk" />
                </div>
                <div className={profileContainerInfo("-avatar-camera")}>
                  <Img className="max-w-[0.4rem]" src={ICON_G_XGTX} alt="g_xgtx_icon" />
                </div>
              </div>
              <div className={profileContainerInfo("-name")}>
                <div className={classNames(profileContainerInfo("-name-nickname"))}>
                  <div className="truncate">{userBaseInfo?.user_name}</div>
                  <Img
                    noLoad
                    className="max-w-[0.26rem] max-h-[0.26rem]"
                    src={Number(userBaseInfo?.user_gender) === 1 ? ICON_NAN : ICON_NV}
                    alt="Avatar"
                  />
                </div>
                <div className={profileContainerInfo("-name-idCard")}>
                  uid:{isGuest ? "--" : userBaseInfo?.uid}
                  {!isGuest && (
                    <Img
                      noLoad
                      className="max-w-[0.26rem] max-h-[0.25rem]"
                      src={ICON_FZ}
                      alt="fz"
                      onClick={() => {
                        copyTextToClipboard(userBaseInfo?.uid?.toString()!, (isCopy) => {
                          if (isCopy) {
                            toast(`${i18n.common("COPIADO_INFO_SUFCCS")}`);
                          } else {
                            toast(`${i18n.common("COPIADO_INFO_ERRO")}`);
                          }
                        });
                      }}
                    />
                  )}
                </div>
                <div className={profileContainerInfo("-name-rank")}>
                  {i18n.common("CURRENCY_SYMBOL")}
                  {isGuest ? "--" : money}
                </div>
              </div>
              <div
                className={profileContainerInfo("-arrow")}
                onClick={() => {
                  dispatch(AuthActions.setGendersAlert(true));
                }}
              >
                <LazyImage
                  height={"0.26rem"}
                  className={profileContainerInfo("-arrow-image")}
                  src={ICON_G_KH as unknown as string}
                  alt="Avatar"
                />
              </div>
            </div>
            <Link href="/vip" onClick={() => dispatch(AuthActions.setAsideAlert(false))}>
              <div
                className={profileVip("")}
                style={{
                  // backgroundImage: `url(${ICON_G_KP})`,
                  background: `url(${cdnPrefix("/profile/g_kp.png")}) no-repeat`,
                  backgroundSize: "100% 100%",
                  borderRadius: "0.2rem",
                }}
              >
                <Vip level={curVipInfo?.curLevel!} />
                <div className={profileVip("-info")}>
                  <div
                    className={classNames(profileVip("-info-text"), "leading-[0.2rem] mb-[0.1rem]")}
                    dangerouslySetInnerHTML={{
                      __html: i18n.translation(
                        "MINE_VIP_DES",
                        curVipInfo?.maxExp - curVipInfo?.curExp,
                        curVipInfo.curLevel + 1,
                      ),
                    }}
                  ></div>

                  <Progress progress={(curVipInfo?.curExp! / curVipInfo?.maxExp!) * 100}>
                    <div className="vip-diff">
                      <span>V{curVipInfo?.curLevel}</span>
                      <span>V{curVipInfo?.curLevel + 1}</span>
                    </div>
                  </Progress>
                  <div className="vip-diff-info">
                    {curVipInfo?.curExp}/{curVipInfo?.maxExp}
                  </div>
                </div>
                {!isGuest && (
                  <div className={profileVip("-arrow")}>
                    <Img noLoad className={profileVip("-arrow-image")} src={ICON_TZHUAN} alt="Avatar" />
                  </div>
                )}
              </div>
            </Link>
            <ProfileMenu />

            <ProfileWallet />

            <ProfileAppDownload />
          </section>
          <div
            ref={closeRef}
            className={profile("-close")}
            onClick={() => dispatch(AuthActions.setAsideAlert(false))}
            style={{ backgroundImage: `url(${BG_ASIDER})` }}
          >
            <Img noLoad className="max-w-[0.17rem] max-h-[0.26rem]" src={ICON_G_ZD} width={17} height={26} />
          </div>
        </aside>
      )}
      <Alert open={show_profile_alert?.bind_phone} onClose={() => dispatch(AuthActions.setBindPhoneAlert(false))}>
        <AlertHeader title={i18n.profile("LYSETTING_32")} />
        <AlertBody>
          <div className="conta-seguranca">
            <div
              className="conta-seguranca-item"
              onClick={() => {
                if (!userBaseInfo?.user_mobile) {
                  dispatch(AuthActions.setBindPhoneCompleteAlert(true));
                }
              }}
            >
              <Img src={ICON_SJ} alt="sj" />
              <span>{i18n.profile("LYSETTING_44")}</span>
              {!userBaseInfo?.user_mobile && (
                <div className="conta-seguranca-item-unbind">
                  <span>{i18n.profile("LYSETTING_46")}</span>
                  <LazyImage
                    height={"0.26rem"}
                    className="!max-w-[0.13rem]"
                    src={ICON_G_KH as unknown as string}
                    alt="g_kh_icon"
                  />
                </div>
              )}

              {userBaseInfo?.user_mobile && <span className="inline-block text-right text-[#ffffff]">{mobile}</span>}
            </div>
            <div
              className="conta-seguranca-item"
              onClick={() => {
                if (!userBaseInfo?.user_mobile) {
                  dispatch(AuthActions.setBindPhoneAlert(false));
                  dispatch(AuthActions.setBindPhoneCompleteAlert(true));
                } else {
                  dispatch(AuthActions.setBindPhoneAlert(false));
                  dispatch(AuthActions.setDefinirSenhaAlert(true));
                }
              }}
            >
              <Img src={ICON_SOU} alt="sou" />
              <span>{i18n.profile("LYSETTING_45")}</span>
              <div className="conta-seguranca-item-unbind">
                {!userBaseInfo?.user_mobile && <span>{i18n.profile("LYSETTING_47")}</span>}

                <LazyImage
                  height={"0.26rem"}
                  className="!max-w-[0.13rem]"
                  src={ICON_G_KH as unknown as string}
                  alt="g_kh_icon"
                />
              </div>
            </div>
            {/* 隐藏巴西支付二级密码 */}
            {/* {isShowBindPsaaword && (
              <div className="conta-seguranca-item">
                <Img src={ICON_ST} alt="sou" />
                <span>{i18n.profile("LYSETTING_1000")}</span>
                <div
                  className="conta-seguranca-item-unbind"
                  onClick={() => {
                    dispatch(AuthActions.setBindPhoneAlert(false));
                    dispatch(AuthActions.setChangePasswordAlert(true));
                  }}
                >
                  <span>{i18n.common("LYSETTING_1001")}</span>
                  <Img noLoad className="!max-w-[0.13rem]" src={ICON_G_KH as unknown as string} alt="g_kh_icon" />
                </div>
              </div>
            )} */}
          </div>
        </AlertBody>
      </Alert>

      <BindPhoneCompleteAlert />
      <DefinirSenhaAlert />
      <SettingsAlert />
      {/* @ts-ignore */}
      <ChangePassword type={withdrawInfo?.pwd_set!} />

      <Logout />
      <AvatarAlert />
      <GendersAlert />
    </>
  );
}

export default ProfileAside;
