import "./AlertBody.scss";
import classNames from "classnames";

interface AlertBodyProps {
  children?: React.ReactNode;
  className?: string;
}

function AlertBody(props: AlertBodyProps) {
  const { children, className } = props;
  return <div className={classNames("common-alert-body", className)}>{children}</div>;
}

export default AlertBody;
