"use client";

import AlertBody from "@/components/Alert/AlertBody";
import Toast from "@/widget/Toast/index";
import { Alert, AlertHeader } from "@/components/Alert";
import { AuthActions } from "@/redux/features/auth-slice";
import { i18n } from "@/utils";
import { useAppSelector } from "@/redux/store";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import md5 from "md5";
import { UserService, WalletService } from "@/service";
import Password from "@/app/withdrawal/password";
import Img from "@/components/Img";
import ICON_ST from "@/assets/profile/seguranca/st.inline.png";

import "./ChangePasswordAlert.scss";

export const ChangePasswordAlert = (props: { type?: boolean }) => {
  const { type = false } = props;
  const show_profile_alert = useAppSelector((state) => state.authSlice.show_profile_alert);
  const set_bing_password_resp = useAppSelector((state) => state.authSlice.set_bing_password_resp);
  const user_basic_info = useAppSelector((state) => state.authSlice.basic_info);
  const dispatch = useDispatch();

  const [params, setParams] = useState<any>({});
  const [formDataRegular, setFormDataRegular] = useState<any>({});

  // 测试重置密码接口
  // useEffect(() => {
  //   if (user_basic_info?.uid) {
  //     UserService.reqResetPassword(user_basic_info?.uid);
  //   }
  // }, [user_basic_info])

  useEffect(() => {
    if (set_bing_password_resp?.ret === 0) {
      dispatch(AuthActions.setChangePasswordAlert(false));
      dispatch(AuthActions.setBindPassword({}));
      WalletService.reqWithdrawInfo();
      Toast("sucesso");
    }
  }, [set_bing_password_resp]);

  const checkData = (value: string, type: string) => {
    const regular: { [key: string]: any } = {
      oldPassword: /^\d{6}$/,
      newPassword: /^\d{6}$/,
      confirm: () => value === params?.newPassword,
    };

    const val =
      value === "" ? false : typeof regular?.[type] === "function" ? regular?.[type]?.() : regular?.[type]?.test(value);
    setFormDataRegular((value: any) => {
      return {
        ...value,
        [type]: val,
      };
    });
    return val;
  };

  const onChange = (value: any, name: any) => {
    setParams((val: any) => {
      return {
        ...val,
        [name]: value,
      };
    });
    checkData(value, name);
  };

  const submit = () => {
    const arr = type ? ["oldPassword", "newPassword", "confirm"] : ["newPassword", "confirm"];
    const isCheck = arr?.every?.((val) => checkData(params?.[val], val));
    if (!isCheck) return;
    const data = type
      ? {
          uid: user_basic_info?.uid,
          old_pwd: md5(params.oldPassword),
          new_pwd: md5(params.newPassword),
        }
      : {
          uid: user_basic_info?.uid,
          new_pwd: md5(params.newPassword),
        };
    UserService.reqBindPassword(data);
  };
  return (
    <Alert
      open={show_profile_alert?.change_password}
      onClose={() => dispatch(AuthActions.setChangePasswordAlert(false))}
    >
      <AlertHeader title={i18n.common("LYSETTING_1000")} />
      <AlertBody>
        {/* 修改密码 */}
        {type ? (
          <>
            <Password
              className="eWallet-change-password"
              leftIcon={ICON_ST as unknown as string}
              placeholder={i18n.profile("SECURITY_INPUT_ORIGINAL_PASSWORD")}
              name="oldPassword"
              onChange={onChange}
              isError={formDataRegular?.oldPassword}
            />
            <Password
              className="eWallet-change-password"
              leftIcon={ICON_ST as unknown as string}
              placeholder={i18n.profile("NEW_PASSWORD_INPUT")}
              name="newPassword"
              onChange={onChange}
              isError={formDataRegular?.newPassword}
            />
            <Password
              className="eWallet-change-password"
              leftIcon={ICON_ST as unknown as string}
              placeholder={i18n.profile("NEW_PASSWORD_CONFIRM")}
              name="confirm"
              onChange={onChange}
              isError={formDataRegular?.confirm}
            />
            <div className="change-password-tips">
              <Img src="/common/tishi.png" />
              <p>{i18n.profile("RESET_PASSWORD_INPUT_6_DIGIT")}</p>
            </div>
            <div className="anniu-xiao">
              <div className="modal-btn-red btn_animation w-[5.37rem]" onClick={submit}>
                {i18n.common("LYSETTING_50")}
              </div>
            </div>
          </>
        ) : (
          <>
            <p className="setting-password-tips">{i18n.profile("WITHDRAWAL_PASSWORD_RESET_TITLE")}</p>
            <Password
              className="eWallet-change-password"
              leftIcon={ICON_ST as unknown as string}
              placeholder={i18n.withdrawal("WITHDRAWAL_ACCOUNT_Y5")}
              name="newPassword"
              onChange={onChange}
              isError={formDataRegular?.newPassword}
            />
            <div className="change-password-tips">
              <Img src="/common/tishi.png" />
              <p>{i18n.profile("RESET_PASSWORD_INPUT_6_DIGIT")}</p>
            </div>
            <Password
              className="eWallet-change-password"
              leftIcon={ICON_ST as unknown as string}
              placeholder={i18n.withdrawal("WITHDRAWAL_ACCOUNT_Y5")}
              name="confirm"
              onChange={onChange}
              isError={formDataRegular?.confirm}
            />
            <div className="anniu-xiao">
              <div className="modal-btn-red btn_animation w-[5.37rem]" onClick={submit}>
                {i18n.common("LYSETTING_43")}
              </div>
            </div>
          </>
        )}
      </AlertBody>
    </Alert>
  );
};

export default ChangePasswordAlert;
