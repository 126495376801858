"use client";

import "./ProfileWallet.scss";
import { useDispatch } from "react-redux";
import { AuthActions } from "@/redux/features/auth-slice";
import { cdnPrefix, i18n } from "@/utils";
import Link from "next/link";
import Img from "@/components/Img";
import ICON_G_KH from "@/assets/profile/g_kh_icon.inline.png";
import ICON_QB from "@/assets/profile/qb.inline.png";

function ProfileWallet() {
  const dispatch = useDispatch();

  return (
    <div className="profile-wallet">
      <Link
        className="profile-wallet-item grid grid-cols-[0.52rem_1fr_0.15rem] gap-[0.15rem]"
        href="/shopping"
        onClick={() => {
          dispatch(AuthActions.setAsideAlert(false));
        }}
        prefetch={true}
        replace={true}
      >
        <Img noLoad src={ICON_QB} height={"100%"} className="w-[0.52rem]" />
        <span>{i18n.home("WALLET_DEPSIT_TEXT")}</span>
        <Img noLoad className="profile-wallet-item-arrow-image" src={ICON_G_KH} alt="g_kh_icon" />
      </Link>
    </div>
  );
}

export default ProfileWallet;
