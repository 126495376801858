"use client";
import { ReduxProvider } from "@/redux/provider";
import { Aside } from "@/widget/Asider";
import { ProfileAside } from "@/widget/Profile";
import "@/utils/i18n";
import Init from "./init";
import NetMessage from "./net-message";
import { FacePull } from "@/app/home/facePull/facePull";
import { TimeoutDialog } from "@/widget/Timeout-Dialog";
import { Goods } from "@/widget/Goods";
import VConsole from "vconsole";
import "react-mobile-datepicker-ts/dist/main.css";
import RouterGuard from "@/components/RouterGuard";
import RegressActivity from "@/components/RegressActivity";
import ActivityButton from "@/widget/Earn/ActivityButton";
import KickPush from "@/components/KickPush";
import AppDownload from "@/components/AppDownload";
import AFReport from "@/components/common/AFReport";
import { useEffect, ReactNode } from "react";
import { isMobile } from "@/utils";

export default function App({
  children,
}: Readonly<{
  children: ReactNode;
}>) {
  useEffect(() => {
    let vConsole: VConsole;
    if (isMobile() && process.env.NODE_ENV === "development") {
      vConsole = new VConsole();
    }
    return () => {
      vConsole?.destroy();
    };
  }, []);

  return (
    <ReduxProvider>
      <RouterGuard>
        <main className="flex flex-col h-full">
          {/* <HeaderBar /> */}
          <Aside />
          <div className="grid grid-rows-[1fr_auto] h-full">
            <section className="w-full overflow-y-auto max-w-[7.68rem] h-full mx-auto global-layout" id="layoutMain">
              {children}
            </section>
            {/* <FooterBar /> */}
          </div>
        </main>
        <Init />
        <NetMessage />
        <ProfileAside />
        <FacePull />
        <TimeoutDialog />
        <Goods />
        <RegressActivity />
        <ActivityButton />
        <KickPush />
        <AppDownload />
        <AFReport />
      </RouterGuard>
    </ReduxProvider>
  );
}
