import { Hall, Recommend } from "@/const";
import { HallActions } from "@/redux/features/hall-slice";
import { EventGlobalManager } from "@/utils/events";
import { useDispatch } from "react-redux";

export default function useHallHooks() {
  const dispatch = useDispatch();

  function onListenSocket() {
    EventGlobalManager.on(Hall.Resp.BANNER_HALL, (rsp: any) => {
      const { data } = rsp;
      console.log("onListenSocket Hall.Resp.BANNER_HALL", data);
      dispatch(HallActions.setBannerHall(data));
    });
    EventGlobalManager.on(Recommend.Resp.FacePull, (rsp: any) => {
      const { data } = rsp;
      console.log("onListenSocket Hall.Resp.FacePull", data);
      dispatch(HallActions.setFacePull(data));
    });
  }

  return {
    onListenSocket,
  };
}
