import { Spin } from "@/const";
import { EventGlobalManager } from "@/utils/events";
import { NetMsg } from "@/utils/net/INet";
import { client_proto } from "@/utils/net/shared/proto";
import { useDispatch } from "react-redux";
import { get } from "lodash-es";
import { SpinActions } from "@/redux/features/spin-slice";

export default function useSpinHooks() {
  const dispatch = useDispatch();

  function onListenSocket() {
    EventGlobalManager.on(Spin.Resp.INFO, (rsp: NetMsg<client_proto.ISpinInfoResp>) => {
      const data = get(rsp, "data");
      dispatch(SpinActions.setInfo(data));
    });

    EventGlobalManager.on(Spin.Resp.SPIN, (rsp: NetMsg<client_proto.ISpinResp>) => {
      const data = get(rsp, "data");
      dispatch(SpinActions.setSpin(data));
    });

    EventGlobalManager.on(Spin.Resp.WITHDRAW, (rsp: NetMsg<client_proto.ISpinWithdrawResp>) => {
      const data = get(rsp, "data");
      dispatch(SpinActions.setWithdraw(data));
    });

    EventGlobalManager.on(Spin.Resp.RECORD, (rsp: NetMsg<client_proto.ISpinOrderLogResp>) => {
      const data = get(rsp, "data");
      dispatch(SpinActions.setRecord(data));
    });

    EventGlobalManager.on(Spin.Resp.ADD, (rsp: NetMsg<client_proto.ISpinRecordAddPush>) => {
      const data = get(rsp, "data");
      dispatch(SpinActions.setAdd(data));
    });

    EventGlobalManager.on(Spin.Resp.CURDATA, (rsp: NetMsg<client_proto.ISpinGetCurDataResp>) => {
      const data = get(rsp, "data");
      dispatch(SpinActions.setCurData(data));
    });

    EventGlobalManager.on(Spin.Resp.POSTER, (rsp: NetMsg<client_proto.ISpinPosterResp>) => {
      const data = get(rsp, "data");
      dispatch(SpinActions.setPoster(data));
    });
  }

  return {
    onListenSocket,
  };
}
