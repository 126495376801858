import { Wallet } from "@/const";
import { WalletActions } from "@/redux/features/wallet-slice";
import { EventGlobalManager } from "@/utils/events";
import { NetMsg } from "@/utils/net/INet";
import { client_proto } from "@/utils/net/shared/proto";
import { useDispatch } from "react-redux";
import { get } from "lodash-es";

// // 提取
// CmdToPbName[client_proto.USER_WALLET_MSG_ID.UWSMI_CREATE_WITHDRAW_ORDER_RESP] = Wallet.Resp.Withdraw.WITHDRAW;

export default function useWalletHooks() {
  const dispatch = useDispatch();

  const onListenSocket = () => {
    EventGlobalManager.on(Wallet.Resp.Deposit.INFO, (rsp: NetMsg<client_proto.IChargeConfigResp>) => {
      const { data } = rsp;
      dispatch(WalletActions.setDepositInfo(data));
    });

    EventGlobalManager.on(Wallet.Resp.Deposit.ORDER, (rsp: NetMsg<client_proto.ICreateChargeOrderResp>) => {
      const data = get(rsp, "data");
      dispatch(WalletActions.setDepositOrder(data));
    });

    EventGlobalManager.on(Wallet.Resp.Deposit.RECORD, (rsp: NetMsg<client_proto.IGetChargeLogResp>) => {
      const { data } = rsp;
      dispatch(WalletActions.setDepositRecord(data));
    });

    EventGlobalManager.on(Wallet.Resp.Deposit.BANNER, (rsp: NetMsg<client_proto.IGetBannerListResp>) => {
      const data = get(rsp, "data");
      dispatch(WalletActions.setDepositBanner(data));
    });

    EventGlobalManager.on(Wallet.Resp.Withdraw.INFO, (rsp: NetMsg<client_proto.IGetWithdrawConfigResp>) => {
      const data = get(rsp, "data");
      dispatch(WalletActions.setWithdrawInfo(data));
    });

    EventGlobalManager.on(Wallet.Resp.Withdraw.SET_BANK, (rsp: NetMsg<client_proto.ISetBankCardResp>) => {
      const data = get(rsp, "data");
      dispatch(WalletActions.setWithdrawBank(data));
    });

    EventGlobalManager.on(Wallet.Resp.Withdraw.WITHDRAW, (rsp: NetMsg<client_proto.ICreateWithdrawOrderResp>) => {
      const data = get(rsp, "data");
      dispatch(WalletActions.setWithdrawOrder(data));
    });

    EventGlobalManager.on(Wallet.Resp.Withdraw.RECORD, (rsp: NetMsg<client_proto.IGetWithdrawLogResp>) => {
      const data = get(rsp, "data");
      dispatch(WalletActions.setWithdrawRecord(data));
    });
  };

  return {
    onListenSocket,
  };
}
