"use client";

import { usePathname, useRouter } from "next/navigation";
import { requiredLogin } from "./login.guard";
import { useAppSelector } from "@/redux/store";
import { useCallback, useMemo } from "react";
import { SettingProvider } from "@/service/setting";
import { useSetState } from "react-use";
import { i18n } from "@/utils";
import { setCookie, getCookie } from "cookies-next";

const RouterGuard = ({ children }: { children: React.ReactNode }) => {
  const loginType = useAppSelector((state) => state.authSlice?.loginType);

  const [settings, setSetting] = useSetState({
    language: getCookie("LANGUAGE") || process.env.NEXT_PUBLIC_TZ!,
  });

  const { push } = useRouter();

  const pathname = usePathname();

  //是否需要登录
  const isRequiredLogin = useMemo(() => {
    return requiredLogin(loginType, pathname);
  }, [loginType, pathname]);

  if (isRequiredLogin) {
    push("/login");
  }
  const onUpdateSetting = useCallback((value: any) => {
    i18n.setLocale(value.language);
    setTimeout(() => {
      setSetting(value);
      setCookie("LANGUAGE", value.language);
    }, 0);
  }, []);

  const contextValue = useMemo(() => {
    return {
      settings,
      setSettings: onUpdateSetting,
    };
  }, [settings, onUpdateSetting]);

  return <SettingProvider value={contextValue}>{children}</SettingProvider>;
};

export default RouterGuard;
