import Image, { StaticImageData } from "next/image";

import "./AlertHeader.scss";
import Img from "../Img";

interface AlertProps {
  // icon: StaticImageData | string;
  width?: number;
  height?: number;
  className?: string;
  useImg?: boolean; // TODO: remove this prop
  title?: string;
}

function Alert(props: AlertProps) {
  const { title, className, width, height, useImg } = props;
  return (
    <div className="common-alert-header">
      <div className="title">
        <span className="linear-clip">{title}</span>
      </div>
      {/* <Img
        className={className || "max-w-[3.06rem]"}
        src={icon as string}
        alt="static-image-data"
        width={width}
        height={height}
      /> */}
    </div>
  );
}

export default Alert;
