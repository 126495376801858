"use client";
import { useState, useRef, useEffect } from "react";
import { useMount } from "react-use";

import "./index.css";

import Img from "@/components/Img";
import ICON_BY from "@/assets/common/by.inline.png";
import ICON_KY from "@/assets/common/ky.inline.png";
import { pngList } from "@/app/withdrawal/png";
import { i18n } from "@/utils";

interface PasswordProps {
  placeholder?: string;
  className?: string;
  onChange: (value: any, name: string) => void;
  onClick?: (value: any) => void;
  onScroll?: (value: any) => void;
  isTips?: boolean;
  leftIcon?: string;
  name?: string;
  isError?: boolean;
  isInput?: boolean;
  autoFocus?: boolean;
}

export const Password = (props: PasswordProps) => {
  const {
    isTips = false,
    placeholder = "",
    onChange,
    name = "",
    isError = true,
    isInput = true,
    className = "",
    leftIcon = pngList.suo,
    onClick,
    onScroll,
    autoFocus,
  } = props;
  const [isShow, setIsShow] = useState(false);

  const [isShowValue, setIsShowValue] = useState(false);
  const ref = useRef<any>();
  const onClickKeyboard = (e: any) => {
    e.stopPropagation();
    const value = e?.target?.dataset?.id;
    const list = ["del", "clear"];
    let data = isInput ? ref.current.value : codeArray.join("");
    if (value && !list?.includes(value)) {
      data = (data + value).substr(0, 6);
    } else if (value === "del") {
      data = data?.substring(0, data?.length - 1);
    } else if (value === "clear") {
      data = "";
    }
    onChange(data, name);
    if (isInput) {
      ref.current.value = data;
    } else {
      const arr = data?.split("");
      setActiveNum(arr?.length);
      const tempCodeArray = codeArray.map((v, i) => (v = arr[i] || ""));
      setCodeArray(tempCodeArray);
    }
  };

  useMount(() => {
    document.addEventListener("click", (e: any) => {
      const className = e?.target?.className;
      const data = e?.target?.dataset?.id;
      const list = ["passwords", "eWallet-keyboard", "eWallet-keyboard-btn", "eWallet-keyboard-img"];
      if (
        !list?.some((val) => typeof className === "string" && className?.indexOf(val) !== -1) ||
        (className?.indexOf("passwords") !== -1 && data !== name)
      ) {
        setIsShow(false);
        setActiveNum(undefined);
      }
    });
  });

  useEffect(() => {
    onClick?.(isShow);
  }, [isShow]);

  const keyboardRef = useRef<any>();
  const inputBoxRef = useRef<any>();
  useEffect(() => {
    if (!keyboardRef?.current) {
      onScroll?.(0);
      return;
    }
    const dom = keyboardRef?.current?.getBoundingClientRect();
    const input = inputBoxRef?.current?.getBoundingClientRect();
    if (dom?.top - input?.top < input?.height) {
      const margeBottom = input?.height - (dom?.top - input?.top);
      onScroll?.(margeBottom + 10);
    } else {
      onScroll?.(0);
    }
  }, [isShow]);

  // 有6个空字符的数组
  const defaultCodeArray = new Array(6).fill("");
  const [codeArray, setCodeArray] = useState(defaultCodeArray);
  const [activeNum, setActiveNum] = useState<number>();
  const handleNumberItemClick = (i: number) => {
    setIsShow(true);
    setActiveNum(i);
  };
  return (
    <div className={`eWallet-password-input ${className}`}>
      {isInput ? (
        <div className="eWallet-input">
          <p>{isError ? "" : "Formato ilegal"}</p>
          <div className={isError ? "" : "error"} ref={inputBoxRef}>
            {leftIcon && <Img src={leftIcon} alt="" />}
            <input
              className="passwords"
              ref={ref}
              type={isShowValue ? "text" : "password"}
              placeholder={placeholder}
              readOnly
              onClick={() => {
                setIsShow(true);
              }}
              data-id={name}
            />
            <Img
              noLoad
              className="passwords"
              src={isShowValue ? ICON_KY : ICON_BY}
              alt=""
              onClick={() => setIsShowValue(!isShowValue)}
            />
          </div>
          {isTips && (
            <div className="eWallet-tips">
              <Img src={pngList.ts} alt="" />
              <p>{i18n.withdrawal("WITHDRAWAL_PASSWORD_ERROR2")}</p>
            </div>
          )}
        </div>
      ) : (
        <div className="number-boxs">
          {codeArray.map((v, i) => {
            return (
              <div
                className={`item-content passwords ${i === activeNum ? "active" : ""}`}
                data-id={name}
                key={i}
                onClick={() => handleNumberItemClick(i)}
              >
                {v !== "" ? <span></span> : ""}
              </div>
            );
          })}
        </div>
      )}
      {isShow && (
        <div ref={keyboardRef} className="eWallet-keyboard" onClick={onClickKeyboard}>
          <div className="eWallet-keyboard-btn" data-id="1">
            1
          </div>
          <div className="eWallet-keyboard-btn" data-id="2">
            2
          </div>
          <div className="eWallet-keyboard-btn" data-id="3">
            3
          </div>
          <div className="eWallet-keyboard-btn" data-id="4">
            4
          </div>
          <div className="eWallet-keyboard-btn" data-id="5">
            5
          </div>
          <div className="eWallet-keyboard-btn" data-id="6">
            6
          </div>
          <div className="eWallet-keyboard-btn" data-id="7">
            7
          </div>
          <div className="eWallet-keyboard-btn" data-id="8">
            8
          </div>
          <div className="eWallet-keyboard-btn" data-id="9">
            9
          </div>
          <div className="eWallet-keyboard-btn" data-id="clear">
            <Img className="eWallet-keyboard-img clear" src={pngList.sc2} data-id="clear" alt="clear" />
          </div>
          <div className="eWallet-keyboard-btn" data-id="0">
            0
          </div>
          <div className="eWallet-keyboard-btn" data-id="del">
            <Img className="eWallet-keyboard-img" src={pngList.srgb} data-id="del" alt="del" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Password;
