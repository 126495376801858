"use client";

import React, { useEffect, useState } from "react";
import { AuthActions } from "@/redux/features/auth-slice";
import { i18n, systemConfigUtils } from "@/utils";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@/redux/store";
import { countUnRead } from "@/app/notify/helper";
import Link from "next/link";
import { useLoginInterceptor } from "@/components/RouterGuard/login.guard";

import ICON_G_TZ from "@/assets/profile/menu/g_tz_icon.inline.png";
import ICON_G_QBICON from "@/assets/profile/menu/g_qbicon.inline.png";
import ICON_G_YZM from "@/assets/profile/menu/g_yzm_icon.inline.png";
import ICON_G_KF from "@/assets/profile/menu/g_kf_icon.inline.png";
import ICON_Gift from "@/assets/profile/menu/gifts.inline.png";
import ICON_G_TC from "@/assets/profile/menu/g_tc_icon.inline.png";
import ICON_G_SZ from "@/assets/profile/menu/g_sz_icon.inline.png";
import ICON_G_TZ_BG from "@/assets/profile/menu/g_tz.inline.png";
import ICON_G_KH from "@/assets/profile/g_kh_icon.inline.png";

import "./ProfileMenu.scss";
import Img from "@/components/Img";

interface ProfileMenuItemProps {
  icon: string;
  text: string;
  arrowTip?: React.ReactNode;
  onClick?: () => void;
  href?: string;
}

function ProfileMenuItem(props: ProfileMenuItemProps) {
  const { icon, text, arrowTip, href, onClick } = props;
  const mailList = useAppSelector((state) => state.emailSlice.mailData);
  const [unRead, setUnRead] = useState({
    all: 0,
  });

  useEffect(() => {
    if (mailList && mailList && unRead.all === 0) {
      setUnRead(countUnRead(mailList));
    }
  }, [mailList, unRead.all]);

  const context = (
    <div className="profile-menu-item btn_animation" onClick={onClick}>
      <Img noLoad className="w-[0.52rem]" src={icon} alt="g_tz_icon" />
      <span>{text}</span>
      <div className="profile-menu-item-arrow">
        {arrowTip && unRead.all > 0 && (
          <span className="profile-menu-item-arrow-tip" style={{ backgroundImage: `url(${ICON_G_TZ_BG})` }}>
            {unRead.all > 0 ? (unRead.all > 99 ? "99+" : unRead.all) : ""}
          </span>
        )}
        <Img noLoad className="profile-menu-item-arrow-image" src={ICON_G_KH as unknown as string} alt="g_kh_icon" />
      </div>
    </div>
  );

  return href ? (
    <Link href={href} prefetch={true}>
      {context}
    </Link>
  ) : (
    context
  );
}

function ProfileMenu() {
  const usr_basic_info = useAppSelector((state) => state.authSlice.basic_info);
  const dispatch = useDispatch();
  const { isGuest } = useLoginInterceptor();

  return (
    <div className="profile-menu">
      <ProfileMenuItem
        icon={ICON_G_TZ as unknown as string}
        text={i18n.profile("EMAIL_TITLE")}
        arrowTip="99+"
        href="/notify"
        onClick={() => {
          dispatch(AuthActions.setAsideAlert(false));
        }}
      />
      <ProfileMenuItem
        icon={ICON_G_QBICON as unknown as string}
        text={i18n.profile("LYSETTING_31")}
        href="/wallet/detail"
        onClick={() => {
          dispatch(AuthActions.setAsideAlert(false));
        }}
      />
      <ProfileMenuItem
        icon={ICON_G_YZM as unknown as string}
        text={i18n.profile("LYSETTING_32")}
        onClick={() => {
          dispatch(AuthActions.setBindPhoneAlert(true));
        }}
      />
      <ProfileMenuItem
        icon={ICON_G_KF as unknown as string}
        text={i18n.profile("LYSETTING_33")}
        onClick={() => {
          window.open(
            `${systemConfigUtils.cs_url}?sfid={${usr_basic_info?.uid}|${usr_basic_info?.user_level || 0}}`,
            "_blank",
          );
        }}
      />
      <ProfileMenuItem
        icon={ICON_Gift as unknown as string}
        text={i18n.profile("LYSETTING_65")}
        href="/gifts"
        onClick={() => {
          dispatch(AuthActions.setAsideAlert(false));
        }}
      />
      <ProfileMenuItem
        icon={ICON_G_SZ as unknown as string}
        text={i18n.profile("LYSETTING_34")}
        onClick={() => {
          dispatch(AuthActions.setSettingsAlert(true));
        }}
      />
      {!isGuest && (
        <ProfileMenuItem
          icon={ICON_G_TC as unknown as string}
          text={i18n.profile("LYSETTING_35")}
          onClick={() => {
            dispatch(AuthActions.setLogoutAlert(true));
          }}
        />
      )}
    </div>
  );
}

export default ProfileMenu;
