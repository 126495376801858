import AlertBody from "@/components/Alert/AlertBody";
import md5 from "md5";
import { Alert, AlertHeader } from "@/components/Alert";
import { AuthActions } from "@/redux/features/auth-slice";
import { cdnPrefix, i18n, imageLoadFadeIn, prefixUtils } from "@/utils";
import { useAppSelector } from "@/redux/store";
import { useDispatch } from "react-redux";
import { useInterval, useSetState } from "react-use";
import { useMemo, useState } from "react";
import { UserService } from "@/service";
import { get } from "lodash-es";
import toast from "../Toast";
import Img from "@/components/Img";
import { Button } from "@/components/Button";

import { useAreaConfig } from "@/hooks";

import ICON_BY from "@/assets/common/by.inline.png";
import ICON_KY from "@/assets/common/ky.inline.png";
import ICON_SJ from "@/assets/profile/seguranca/sj.inline.png";
import ICON_YZM from "@/assets/profile/seguranca/yanzm.inline.png";
import ICON_SRYZ from "@/assets/profile/seguranca/sryz.inline.png";
import ICON_ST from "@/assets/profile/seguranca/suo.inline.png";

const imgPrefix = prefixUtils("/login/");

function DefinirSenhaAlert() {
  const alertReduxState = useAppSelector((state) => state.authSlice.show_profile_alert);

  const [seconds, setSeconds] = useState(0);
  const { getPhoneAreaLength } = useAreaConfig();

  const dispatch = useDispatch();

  const [form, setForm] = useSetState<{
    phone: string;
    code: string;
    password: string;
    password_confirm: string;
  }>({
    phone: "",
    code: "",
    password: "",
    password_confirm: "",
  });

  const [openState, setOpenState] = useSetState({
    password: false,
    password_confirm: false,
  });

  const showPasswordIcon = useMemo(() => {
    return !openState.password ? ICON_BY : ICON_KY;
  }, [openState.password]);

  const showPasswordConfirmIcon = useMemo(() => {
    return !openState.password_confirm ? ICON_BY : ICON_KY;
  }, [openState.password_confirm]);

  const onSendSmsCode = () => {
    let phone = form.phone;
    if (!phone) {
      toast(i18n.login("LYSETTING_53")); // 提示用户手机号码不能为空
      return;
    }
    const phoneLength = getPhoneAreaLength();
    if (phone.length < phoneLength) {
      return toast(i18n.login("LYSETTING_9"));
    }
    UserService.sendSmsCode(phone).then((res) => {
      if (res.code === 0) {
        toast(i18n.login("LYSETTING_12"));
        return setSeconds(60);
      }
      toast(res.msg);
    });
  };

  const onBindPhone = () => {
    const { phone, code, password, password_confirm } = form;
    if (!phone) {
      toast(i18n.login("LYSETTING_53")); // 手机号码不能为空
      return;
    }
    if (!code) {
      toast(i18n.login("LYSETTING_49")); // 验证码不能为空
      return;
    }
    if (!password) {
      toast(i18n.login("LYSETTING_54")); // 密码不能为空
      return;
    }
    if (!password_confirm) {
      toast(i18n.login("LYSETTING_17")); // 确认密码不能为空
      return;
    }
    if (password !== password_confirm) {
      toast(i18n.common("LYSETTING_17")); // 两次密码输入不一致
      return;
    }

    UserService.setPassword({
      phone: form.phone,
      code: form.code,
      password: md5(form.password),
    })
      .then((res) => {
        console.log("bind phone", res);
        const code = get(res, "code", {});
        if (code === 0) {
          toast(`${i18n.common("MODE_SUCCESS")}`);
        } else {
          const msg = get(res, "msg", "");
          toast(msg);
        }
      })
      .finally(() => {
        setForm({});
        onClose();
      });
  };

  useInterval(
    () => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    },
    seconds > 0 ? 1000 : null,
  );

  const clearData = () => {
    setForm({
      phone: "",
      code: "",
      password: "",
      password_confirm: "",
    });
  };

  const onClose = () => {
    dispatch(AuthActions.setDefinirSenhaAlert(false));
    clearData();
  };

  return (
    <Alert open={alertReduxState?.definir_senha} isLong={true} onClose={() => onClose()}>
      <AlertHeader title={i18n.login("LOGIN_TITLE")} />
      <AlertBody>
        <div className="conta-seguranca">
          <div className="conta-seguranca-item account-bind-item">
            <Img noLoad src={ICON_SJ} alt="sj" />
            <div className="conta-seguranca-item-phone">
              <span className="area-code">{i18n.login("PHONE_AREA_CODE")}</span>
              <span className="line" />
              <input
                value={form?.phone}
                placeholder={i18n.profile("LYSETTING_5")}
                onChange={(e) => setForm({ phone: e.target.value })}
                onInput={(e: React.FormEvent<HTMLInputElement>) => {
                  const target = e.target as HTMLInputElement;
                  target.value = target.value.slice(0, getPhoneAreaLength());
                }}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="conta-seguranca-item account-bind-item">
            <Img noLoad src={ICON_YZM} alt="yanzm" />
            <div className="code-container">
              <input
                value={form?.code}
                placeholder={i18n.profile("LYSETTING_49")}
                onChange={(e) => setForm({ code: e.target.value })}
                onInput={(e: React.FormEvent<HTMLInputElement>) => {
                  const target = e.target as HTMLInputElement;
                  target.value = target.value.slice(0, 6);
                }}
                autoComplete="off"
              />
              {seconds === 0 && (
                <Button
                  className="!h-[.45rem] w-[1.04rem] !p-0 !text-[0.18rem]"
                  styleType="default"
                  onClick={onSendSmsCode}
                >
                  {i18n.profile("LYSETTING_52")}
                </Button>
              )}
              {seconds > 0 && <span className="code-time text-[0.28rem]">{seconds}</span>}
            </div>
          </div>

          <div className="conta-seguranca-item account-bind-item">
            <Img noLoad src={ICON_SRYZ} alt="yanzm" width={39} height={47} />
            <div className="code-container">
              <input
                type={openState?.password ? "text" : "password"}
                value={form?.password}
                placeholder={i18n.profile("LYSETTING_61")}
                onChange={(e) => setForm({ password: e.target.value })}
                autoComplete="off"
              />
              <Img
                width={42}
                height={25}
                className="max-w-[0.42rem]"
                src={showPasswordIcon}
                alt="Enviar"
                onClick={() => {
                  setOpenState({
                    password: !openState.password,
                  });
                }}
              />
            </div>
          </div>

          <div className="conta-seguranca-item account-bind-item">
            <Img src={ICON_ST} alt="yanzm" width={39} height={47} />
            <div className="code-container">
              <input
                type={openState?.password_confirm ? "text" : "password"}
                value={form?.password_confirm}
                placeholder={i18n.profile("LYSETTING_62")}
                onChange={(e) => setForm({ password_confirm: e.target.value })}
                autoComplete="off"
              />
              <Img
                width={42}
                height={25}
                className="max-w-[0.42rem]"
                src={showPasswordConfirmIcon}
                alt="Enviar"
                onClick={() => {
                  setOpenState({
                    password_confirm: !openState.password_confirm,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <Button className="modal-btn-red min-w-[2.66rem] mt-[0.5rem]" onClick={onBindPhone}>
            {i18n.common("LYSETTING_50")}
          </Button>
        </div>
      </AlertBody>
    </Alert>
  );
}
export default DefinirSenhaAlert;
