import React from "react";
import Link from "next/link";
import { useAppSelector } from "@/redux/store";
import { systemConfigUtils } from "@/utils";

export const BannerLink = (props: any) => {
  const { children, href, prefetch = true, ...attr } = props;
  const usr_basic_info = useAppSelector((state) => state.authSlice.basic_info);
  const isUrl = ["http://", "https://"].some((val) => href?.indexOf(val) !== -1);
  let url = href;
  if (href === systemConfigUtils.cs_url) {
    url = `${href}?sfid=${usr_basic_info?.uid}|${usr_basic_info?.user_level || 0}`;
  }
  return isUrl ? (
    <a {...attr} target="_blank" href={url}>
      {children}
    </a>
  ) : (
    <Link {...attr} prefetch={prefetch} href={url}>
      {children}
    </Link>
  );
};

export default BannerLink;
