import { useAppSelector } from "@/redux/store";
import { UserService } from "@/service";
import { useMemo } from "react";

function useLogReport() {
  const use_basic_info = useAppSelector((state) => state?.authSlice?.basic_info);
  const logReport = useMemo(() => {
    return UserService.logReport(use_basic_info?.uid?.toString()!)("web");
  }, [use_basic_info?.uid]);

  return {
    logReport,
  };
}

export default useLogReport;
