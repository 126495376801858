import { Activity } from "@/const";
import { ActivityActions } from "@/redux/features/activity-slice";
import { EventGlobalManager } from "@/utils/events";
import { NetMsg } from "@/utils/net/INet";
import { client_proto } from "@/utils/net/shared/proto";
import { get } from "lodash-es";
import { useDispatch } from "react-redux";

export default function useActivityHooks() {
  const dispatch = useDispatch();

  function onListenSocket() {
    EventGlobalManager.on(Activity.Resp.ActivityList, (rsp: NetMsg<client_proto.IActivityListReq>) => {
      const data = get(rsp, "data");
      // console.log("rsp", rsp);
      // console.log("data", data);
      if (data) {
        dispatch(ActivityActions.setActivityList(data));
      }
    });

    EventGlobalManager.on(Activity.Resp.RechargeCreatOrder, (rsp: any) => {
      dispatch(ActivityActions.setRechargeCreatOrder(rsp.data));
    });

    EventGlobalManager.on(Activity.Resp.CompleteChargeOrder, (rsp: any) => {
      dispatch(ActivityActions.setCompleteChargeOrder(rsp.data));
    });

    EventGlobalManager.on(Activity.Resp.ActivityResultResp, (rsp: any) => {
      dispatch(ActivityActions.setActivityResultResp(rsp.data));
    });
    EventGlobalManager.on(Activity.Resp.ActivityItem, (rsp: any) => {
      dispatch(ActivityActions.setActivityItem(rsp.data));
    });
    EventGlobalManager.on(Activity.Resp.TotalRechargeGetHistory, (rsp: any) => {
      dispatch(ActivityActions.settotalRechargeGetHistory(rsp.data));
    });
    EventGlobalManager.on(Activity.Resp.LookEventDetail, (rsp: any) => {
      dispatch(ActivityActions.setlookEventDetail(rsp.data));
    });
    EventGlobalManager.on(Activity.Resp.TotalRechargeDrawSpin, (rsp: any) => {
      dispatch(ActivityActions.settotalRechargeDrawSpin(rsp.data));
    });
    EventGlobalManager.on(Activity.Resp.TotalRechargeUnLock, (rsp: any) => {
      dispatch(ActivityActions.settotalRechargeUnLock(rsp.data));
    });
    EventGlobalManager.on(Activity.Resp.TotalRechargeGetBroadcastMessage, (rsp: any) => {
      dispatch(ActivityActions.settotalRechargeGetBroadcastMessage(rsp.data));
    });
    EventGlobalManager.on(Activity.Resp.ActivityItemPush, (rsp: any) => {
      dispatch(ActivityActions.setactivityItemPush(rsp.data));
    });
  }

  return {
    onListenSocket,
  };
}
