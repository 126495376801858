import { ExchangeCode } from "@/const";
import { ExchangeCodeActions } from "@/redux/features/exchange-code-slice";
import { EventGlobalManager } from "@/utils/events";
import { useDispatch } from "react-redux";

function useExchangeCodeHooks() {
  const dispatch = useDispatch();

  function onListenSocket() {
    EventGlobalManager.on(ExchangeCode.Resp.GiftGetShowPage, (rsp: any) => {
      const { data } = rsp;
      dispatch(ExchangeCodeActions.setGiftGetShowPage(data));
    });

    EventGlobalManager.on(ExchangeCode.Resp.OpenGift, (rsp: any) => {
      const { data } = rsp;
      dispatch(ExchangeCodeActions.setOpenGift(data));
    });
  }

  return {
    onListenSocket,
  };
}

export default useExchangeCodeHooks;
