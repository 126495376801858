"use client";

import {
  useActivityHooks,
  useAgentHooks,
  useAssetHooks,
  useEmailHooks,
  useHallHooks,
  useRankHooks,
  useRenverHooks,
  useSpinHooks,
  useThirdGameHooks,
  useUserHook,
  useVipHooks,
  useWalletHooks,
  useWheelHooks,
  useGoodsHooks,
  useMyBonusHooks,
  useAgentRankHooks,
  useExchangeCodeHooks,
} from "@/hooks";
import useRecommendHooks from "@/hooks/recommend";
import { AppDispatch } from "@/redux/store";
import { useDispatch } from "react-redux";
import { useMount } from "react-use";

function NetMessage() {
  const dispatch = useDispatch<AppDispatch>();
  const { onListenSocket: onActivitySocket } = useActivityHooks();
  const { onListenSocket: onAgentSocket } = useAgentHooks();
  const { onListenSocket: onAssetSocket } = useAssetHooks();
  const { onListenSocket: onEmailSocket } = useEmailHooks();
  const { onListenSocket: onGoodsSocket } = useGoodsHooks();
  const { onListenSocket: onHallSocket } = useHallHooks();
  const { onListenSocket: onRankSocket } = useRankHooks();
  const { onListenSocket: onRecommendSocket } = useRecommendHooks();
  const { onListenSocket: onRenverSocket } = useRenverHooks();
  const { onListenSocket: onSpinSocket } = useSpinHooks();
  const { onListenSocket: onThirdGameSocket } = useThirdGameHooks();
  const { onListenSocket: onUserSocket } = useUserHook();
  const { onListenSocket: onVipSocket } = useVipHooks();
  const { onListenSocket: onWalletSocket } = useWalletHooks();
  const { onListenSocket: onWheelSocket } = useWheelHooks();
  const { onListenSocket: onMyBonusSocket } = useMyBonusHooks();
  const { onListenSocket: onAgentRankSocket } = useAgentRankHooks();
  const { onListenSocket: onExchangeCodeSocket } = useExchangeCodeHooks();

  useMount(() => {
    onActivitySocket();
    onAgentSocket();
    onAgentRankSocket();
    onAssetSocket();
    onEmailSocket();
    onGoodsSocket();
    onHallSocket();
    onRankSocket();
    onRecommendSocket();
    onRenverSocket();
    onSpinSocket();
    onSpinSocket();
    onThirdGameSocket();
    onUserSocket();
    onVipSocket();
    onWalletSocket();
    onWheelSocket();
    onMyBonusSocket();
    onExchangeCodeSocket();
    console.log("init socket");
  });
  return <></>;
}

export default NetMessage;
