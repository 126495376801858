import { client_proto } from "@/utils/net/shared/proto";
import * as R from "ramda";
import { systemConfigUtils } from "@/utils";
import { useDispatch } from "react-redux";
import { ActivityService } from "@/service";
import { useAppSelector } from "@/redux/store";
import { ActivityActions } from "@/redux/features/activity-slice";
import { useRouter } from "next/navigation";

interface BannerJump {
  gt_page: string;
  gt_params?: string;
}

export function decodeUrl(paramsStr: string): BannerJump {
  const searchParams = new URLSearchParams(paramsStr);
  const params: Record<string, any> = {
    gt_page: "",
  };
  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }
  return params as BannerJump;
}

function decodeParams(paramsStr: string) {
  const isValidPair = R.test(/^\w+:[^|]+$/);

  const handleParams = R.pipe(
    R.split("|"),
    R.reduce((acc: Record<string, any>, pair: string) => {
      const [key, value] = pair.split(":");
      if (isValidPair(pair)) {
        acc[key] = value;
      }
      return acc;
    }, {}),
  );

  // 定义一个正常的参数字符串
  const paramsStrNormal = () => ({ id: paramsStr }) as Record<string, any>;

  return R.ifElse(R.pipe(R.split("|"), R.length, R.gt(R.__, 0)), handleParams, paramsStrNormal)(paramsStr);
}

export function bannerJump(data: client_proto.IGameBannerUserData) {
  const { jump } = data;

  if (!jump) {
    return;
  }

  const { gt_page, gt_params } = decodeUrl(jump);

  let url = "";
  url = typeof bannerMap?.[gt_page] === "function" ? bannerMap?.[gt_page]?.(gt_params) : bannerMap?.[gt_page];

  if (gt_params) {
    if (gt_page !== "web") {
      const params = decodeParams(gt_params);
      const isParams = Object.keys(params)?.length > 0;
      url += isParams ? `?${new URLSearchParams(params as Record<string, any>).toString()}` : "";
    }
  }

  console.log("🚀 ~ BannerTop ~ log:", url);

  return url;
}

const bannerMap: any = {
  // 绑定账号
  bindaccount: "",
  // 大转盘
  luckywheel: "/turntable",
  // 排行榜
  rank: (gt_params: string) => `/ranking${gt_params ? `?gt_params=${gt_params}` : ""}`,
  // (代理)排行榜-我的奖励
  rankrewards: (gt_params: string) => {
    // 代理排行榜奖励
    if (gt_params && gt_params === "agent") {
      return "/ranking/myReward?type=2";
    } else {
      return "/ranking/myReward";
    }
  },
  // 代理
  agent: "/earn",
  // VIP
  vip: (gt_params: string) => `/vip${gt_params ? `?gt_params=${gt_params}` : ""}`,
  // 邮件列表
  email: "/notify",
  // 邮件详情
  emaildetail: (gt_params: string) => `/notify/detail${gt_params ? `?id=${gt_params}` : ""}`,
  // 活动列表
  activity: "/activity",
  // 活动详情
  activitygift: "activitygift",
  // 首充活动（已废弃）
  firstrecharge: "",
  // 破冰礼包（已废弃）
  breakicegift: "",
  // 钱包主界面
  wallet: "/wallet",
  // 充值(商城)界面
  deposit: "/shopping",
  // 提现界面
  withdraw: "/withdrawal",
  // 我的奖励
  mybonus: "/?gt_params=mybonus",
  // 进入某个游戏厂商标签(二级大厅)
  gamelist: "/gameList",
  // 进入某个3方游戏的房间
  otherroom: "/gameList/game",
  // 进入某个自研 游戏的房间
  ownroom: "/gameList/game",
  // http地址
  web: (gt_params: string) => gt_params,
  // 联系客服
  customerservice: () => {
    return systemConfigUtils.cs_url;
  },
  // 兑换码
  codekey: "/gifts",
  // 电报分享
  sharetelegram: (gt_params: string) => "https://telegram.me/share/url?url=" + gt_params,
  // whatsapp分享
  sharewhatsapp: (gt_params: string) => "https://api.whatsapp.com/send?text=" + gt_params,
  //app下载
  ownloadApk: "downloadApk",
};

export const useBanner = () => {
  const dispatch = useDispatch();
  const usr_basic_info = useAppSelector((state) => state?.authSlice?.basic_info);
  const { push } = useRouter();
  const bannerLinkTo = (
    data: client_proto.IActivityItem | client_proto.IBannerData,
    e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if ("jump" in data) {
      //banner类型
      let link = bannerJump(data);
      if (!link) {
        console.error("解析banner链接失败：", data);
        return;
      }
      if (link === "activitygift") {
        let jumpData = decodeUrl(data.jump!);
        goRegressionGift(parseInt(jumpData.gt_params as string));
      } else {
        push(link);
      }
    } else {
      //活动类型
      const activityItem = data as client_proto.IActivityItem;
      const jsonData =
        typeof activityItem?.basejson === "string" ? JSON.parse(activityItem?.basejson!) : activityItem.basejson;
      const { activity_type } = activityItem;
      switch (activity_type) {
        case 1:
          if (jsonData.click_type === 1) {
            //直接购买
            if (activityItem.giftuserdata && activityItem.giftuserdata[0]) {
              ActivityService.reqRechargeCreatOrder({
                uid: usr_basic_info?.uid,
                id: activityItem?.id!,
                subid: activityItem.giftuserdata[0].subid,
              });
              return;
            } else {
              console.warn("直接购买失败===>无商品配置", activityItem);
            }
          } else {
            //跳转商品详情
          }
          break;
        case 3:
          // let href = bannerJump(activityItem);
          break;
        case 4:
          push(`/totalRecharge?id=${activityItem.id!}`);
          break;
        case 5:
          goRegressionGift(activityItem.id!);
          e?.preventDefault();
          break;
        case 6: //回归福利
          dispatch(
            ActivityActions.setShowRegressActivty({
              id: activityItem?.id!,
            }),
          );
          // let mailList = [];
          // if (mailList.length === 0) {
          //   try {
          //     mailList = JSON.parse((StorageData.local.get(StoreKey.EMAIL_LIST) as string) || "[]");
          //   } catch (e) {}
          // }
          // const mailIds = getEmailIDListByActivityID(activityItem.id!, mailList, true);
          // if (mailIds.length > 0) {
          //   push(`/notify/detail?id=${mailIds[0]}&type=2`);
          // }
          break;
      }
    }
  };
  //跳转回归礼包
  const goRegressionGift = (id: number) => {
    dispatch(
      ActivityActions.setShowRegressActivty({
        id: id,
      }),
    );
  };
  // 跳转回归福利
  const goRegressionWelfare = () => {};

  //下载
  const downloadApk = (activity: client_proto.IFaceData) => {
    const jumpData = decodeUrl(activity.jump!);
    dispatch(
      ActivityActions.setAppDownload({
        isVisible: true,
        target: jumpData.gt_params,
      }),
    );
  };

  return {
    bannerLinkTo,
    goRegressionGift,
    goRegressionWelfare,
    downloadApk,
  };
};
