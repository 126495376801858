import { AuthActions } from "@/redux/features/auth-slice";
import { useDispatch } from "react-redux";
import "@/widget/Form/style.scss";
import "./index.css";
import { useRouter } from "next/navigation";
import { useAppSelector } from "@/redux/store";
import { useEffect, useMemo } from "react";
import { UserService } from "@/service";
import { useLogReport } from "@/hooks";
import { i18n } from "@/utils";
import { Alert, AlertHeader } from "@/components/Alert";
import AlertBody from "@/components/Alert/AlertBody";
import { Button } from "../Button";
const TouristModal = () => {
  const dispatch = useDispatch();

  const { push } = useRouter();

  const isOpen = useAppSelector((state) => state.authSlice?.show_profile_alert?.tourist_modal);
  const user = useAppSelector((state) => state.authSlice.basic_info);

  const close = () => {
    dispatch(AuthActions.setTouristModal(false));
  };

  const pushLogin = (type: string) => {
    close();
    push(`/login?limit=1&type=${type}&url=${window.location.href}`);
  };

  const onClose = () => {
    logReport("4");
    close();
  };

  const onLoginClick = () => {
    logReport("6");
    pushLogin("login");
  };

  const { logReport } = useLogReport();

  useEffect(() => {
    if (isOpen) {
      logReport("3");
    }
  }, [isOpen, logReport]);

  return (
    <>
      <Alert open={isOpen} onClose={onClose}>
        <AlertHeader title={i18n.common("INCITAR")} />
        <AlertBody>
          <div className="tourist-modal">
            <p className="des">{i18n.home("OPERATION_TIPS")}</p>
          </div>
          <div className="mt-[1.4rem] flex gap-[0.23rem]">
            <Button styleType="red" onClick={onLoginClick} className="flex-1">
              <span>{i18n.common("LOGIN")}</span>
            </Button>
            <Button
              className="flex-1"
              styleType="yellow"
              onClick={() => {
                logReport("5");
                pushLogin("register");
              }}
            >
              <span>{i18n.home("HOME_REGISTAR")}</span>
            </Button>
          </div>
        </AlertBody>
      </Alert>
    </>
  );
};

export default TouristModal;
