"use client";
import AlertBody from "@/components/Alert/AlertBody";
import { Alert, AlertHeader } from "@/components/Alert";
import { AuthActions } from "@/redux/features/auth-slice";
import { HallActions } from "@/redux/features/hall-slice";
import { cdnPrefix, i18n } from "@/utils";
import { StorageData } from "@/utils/net/storage/StorageData";
import { StoreKey } from "@/const";
import { useAppSelector } from "@/redux/store";
import { useDispatch } from "react-redux";
import { useRouter } from "next/navigation";
import "./Logout.scss";
import { UserService } from "@/service";
import { Button } from "@/components/Button";

function Logout() {
  const show_profile_alert = useAppSelector((state) => state.authSlice.show_profile_alert);
  const dispatch = useDispatch();
  const { push } = useRouter();

  const onClose = () => {
    dispatch(AuthActions.setLogoutAlert(false));
  };

  const onLogout = () => {
    dispatch(AuthActions.setAsideAlert(false));
    dispatch(AuthActions.setLogoutAlert(false));
    dispatch(HallActions.setFace(false));
    UserService.onLogout();
    setTimeout(() => {
      push("/login");
    }, 50);
  };

  return (
    <Alert open={show_profile_alert?.logout} onClose={onClose}>
      <AlertHeader title={i18n.profile("Warning")} />
      <AlertBody>
        <div className="flex flex-col">
          <div className="flex items-center justify-center h-[3.5rem] text-[#CCC1C2]">{i18n.profile("sameless_3")}</div>

          <div className="flex justify-between">
            {/* <div className="logout-btn-canal" onClick={onClose}>
              {i18n.profile("sameless_7")}
            </div> */}
            <Button styleType="red" className="logout-btn-canal" onClick={onClose}>
              {i18n.profile("sameless_7")}
            </Button>
            <Button styleType="yellow" className="logout-btn-confirmar" onClick={onLogout}>
              {i18n.profile("sameless_6")}
            </Button>
          </div>
        </div>
      </AlertBody>
    </Alert>
  );
}

export default Logout;
