import { Asset } from "@/const";
import { EventGlobalManager } from "@/utils/events";
import { asset } from "@/utils/net/shared/proto";
import { useDispatch } from "react-redux";
import { get } from "lodash-es";
import { AssetResp } from "@/redux/features/auth-slice";

export default function useAssetHooks() {
  const dispatch = useDispatch();

  function onListenSocket() {
    EventGlobalManager.on(Asset.Resp.Asset, (rsp: any) => {
      const data = get(rsp, "data", {});
      if (data) {
        const { amount, coin, rechargecount, result, totalbet, totalrecharge } = data;
        dispatch(
          AssetResp({
            amount,
            coin,
            rechargecount,
            result,
            totalbet,
            totalrecharge,
          }),
        );
      }
    });
  }

  return {
    onListenSocket,
  };
}
