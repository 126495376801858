import { Team } from "@/const";
import { EventGlobalManager } from "@/utils/events";
import { useDispatch } from "react-redux";

export default function useTeamHooks() {
  const dispatch = useDispatch();

  const onListenSocket = () => {
    EventGlobalManager.on(Team.Resp.USER_TEAM_INFO, (rsp: any) => {
      const { data } = rsp;
    });
  };

  return {
    onListenSocket,
  };
}
