/**
 * 回归礼包活动
 */
import "animate.css";
import { i18n, systemConfigUtils } from "@/utils";
import { Button } from "../Button";
import { LazyImage } from "../LazyloadImage";
import "./index.css";
import Img from "../Img";
import gb_anniu from "@/assets/common/gb_anniu.inline.png";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "@/redux/store";
import { useDispatch } from "react-redux";
import { ActivityActions } from "@/redux/features/activity-slice";
import { ActivityService, EmailService } from "@/service";
import { EventGlobalManager } from "@/utils/events";
import { Activity, StoreKey } from "@/const";
import { client_proto } from "@/utils/net/shared/proto";
import EndTime from "../EndTime";
import toast from "@/widget/Toast";
import { get } from "lodash-es";
import { getEmailIDListByActivityID } from "@/app/notify/helper";
import { useRouter } from "next/navigation";
import { StorageData } from "@/utils/net/storage/StorageData";
import { PartLoading } from "../Loading";
import { useMount } from "react-use";

export const CLOSE_GIFT_EVENT = "CLOSE_GIFT_EVENT";

export const RECEIVE_GIFT_RESULT = "RECEIVE_GIFT_RESULT";
const parseBaseJson = (json?: string | null) => {
  if (!json) {
    return {};
  }
  let data = {};
  try {
    data = JSON.parse(json);
  } catch (e) {
    console.log(e);
  }
  return data;
};
const getEndTime = (newTime: number) => {
  let time = systemConfigUtils.delayTime || Date.now();
  if (newTime - time / 1000 < 0) {
    return 0;
  }
  let ms = newTime - time / 1000;
  return parseInt(ms.toFixed(0));
};

/** 检查是否可购买礼包 */
const checkCanBuyGift = (oneData: client_proto.IRegressGiftItem) => {
  if (oneData.gifttotalnum! > -1) {
    if (oneData.gifttotalnum! - oneData.expendgiftnum! <= 0) {
      //商品剩余总数不足
      // result.canBuy = false;
      // result.toast = i18n.activiy('ACTIVITY_GIFT_HAD_SOLD');
      // result.btnLabel = i18n.activiy('ACTIVITY_GIFT_BTN_1');
      return i18n.activiy("ACTIVITY_GIFT_HAD_SOLD");
    }
  }
  if (oneData.buygiftnum! > -1) {
    if (oneData.buygiftnum! - oneData.purchasedgiftnum! <= 0) {
      //已达最大购买次数
      // result.canBuy = false;
      // result.toast = gutil_char('ACTIVITY_GIFT_TO_MAX');
      // result.btnLabel = gutil_char('ACTIVITY_GIFT_BTN_2');
      return i18n.activiy("ACTIVITY_GIFT_TO_MAX");
    } else {
      if (oneData.buygiftnum! > 1) {
        // result.btnLabel = `${result.btnLabel} (${oneData.purchasedgiftnum}/${oneData.buygiftnum})`;
      }
    }
  }
  return true;
};

const ReturnGiftActivity = memo(() => {
  const dispatch = useDispatch();

  const router = useRouter();

  const usr_basic_info = useAppSelector((state) => state?.authSlice?.basic_info);

  const mailData = useAppSelector((state) => state.emailSlice.mailData);

  const regressActivity = useAppSelector((state) => state?.activitySlice?.showRegressActivty);

  const detail = useAppSelector((state) => state?.activitySlice?.activityItemPush);

  const rechargeCreatOrder = useAppSelector((state) => state?.activitySlice?.rechargeCreatOrder);

  const [isLoading, setIsLoading] = useState(false);

  const currentId = regressActivity?.id;

  const fromType = regressActivity?.type || 1;

  const { onCloseCallback } = regressActivity || {};

  useMount(() => {
    if (currentId) {
      dispatch(ActivityActions.setShowRegressActivty(null));
      dispatch(ActivityActions.setactivityItemPush(null));
    }
  });

  useEffect(() => {
    if (usr_basic_info?.uid && regressActivity?.id) {
      ActivityService.reqActivityList(usr_basic_info.uid, regressActivity.id as number);
    }
    return () => {
      // onClose();
    };
  }, [usr_basic_info, regressActivity]);

  const activityType = useMemo(() => {
    if (detail) {
      return detail.item?.activity_type;
    }
  }, [detail]);

  const onClose = useCallback(() => {
    dispatch(ActivityActions.setactivityItemPush(null));
    dispatch(ActivityActions.setShowRegressActivty(null));
    onCloseCallback?.();
  }, [currentId]);

  useEffect(() => {
    if (rechargeCreatOrder && rechargeCreatOrder?.result !== undefined) {
      const { result, url } = rechargeCreatOrder;
      setIsLoading(false);
      if (result != 0) {
        console.log("活动下单错误：", result);
        let key = "ACTIVITY_CREATE_ORDER_ERR_" + result;
        toast(i18n.activiy(key) || "SERVER ERROR");
        return;
      }
      console.log("活动下单返回：", result);
      onClose();
      EventGlobalManager.emit(RECEIVE_GIFT_RESULT, true);
      if (url) {
        window.open(url);
      }
      dispatch(ActivityActions.setRechargeCreatOrder(null));
    }
  }, [rechargeCreatOrder]);

  //回归福利
  useEffect(() => {
    if (!detail) {
      return;
    }
    const activity_type = detail?.item?.activity_type;
    if (activity_type === 4) {
      if (currentId && fromType === 1) {
        onClose();
        router.push(`/totalRecharge/?id=${detail?.item?.id}`);
      }
    } else if (activity_type !== 5 && activity_type !== 6) {
      onClose();
    }
  }, [detail]);

  const pic = useMemo(() => {
    if (detail) {
      let pic = "";
      if (detail?.item?.activity_type === 5) {
        detail.item?.regressgiftuserdata?.forEach((item) => {
          if (!pic && item.goodsimg) {
            pic = item.goodsimg;
          }
        });
      } else if (detail?.item?.activity_type === 6) {
        let baseJson = parseBaseJson(detail.item.basejson);
        pic = get(baseJson, "detail_url", "");
      }
      return pic;
    }
    return "";
  }, [detail]);

  // 倒计时
  const endTimes = useMemo(() => {
    if (detail && detail.item) {
      const { end_time, showtype } = detail.item;
      if (showtype === 2) {
        //指定时间
        if (end_time !== null && end_time !== undefined && end_time > -1) {
          return getEndTime(end_time);
        }
        return 0;
      }
    }
    return 0;
  }, [detail, usr_basic_info]);

  const price = useMemo(() => {
    if (detail) {
      let priceValue = 0;
      detail.item?.regressgiftuserdata?.forEach((item) => {
        if (!priceValue && item.price) {
          priceValue += item.price;
        }
      });
      return priceValue / 1000;
    }
    return 0;
  }, [detail]);
  const onBuy = () => {
    const oneData = detail?.item?.regressgiftuserdata?.[0];
    if (oneData && checkCanBuyGift(oneData)) {
      setIsLoading(true);
      ActivityService.reqRechargeCreatOrder({
        uid: usr_basic_info?.uid!,
        id: currentId,
        subid: oneData?.subid,
      });
    }
  };

  const onGoEmailDetail = () => {
    let mailList = mailData;
    if (mailList.length === 0) {
      try {
        mailList = JSON.parse((StorageData.local.get(StoreKey.EMAIL_LIST) as string) || "[]");
      } catch (e) {}
    }
    const mailIds = getEmailIDListByActivityID(currentId!, mailData, true);

    if (mailIds.length > 0) {
      onClose();
      return router.push(`/notify/detail?id=${mailIds[0]}&type=2`);
    }
    toast(i18n.activiy("REGRESS_WELFARE_JUMP_ERR"));
  };

  const pickMailBtnText = useMemo(() => {
    const data = parseBaseJson(detail?.item?.basejson);
    const btnText = get(data, "btn_text", "");
    return btnText;
  }, [detail]);

  useEffect(() => {
    if (endTimes <= 0) {
      onClose();
      EventGlobalManager.emit(CLOSE_GIFT_EVENT, currentId);
    }
  }, [endTimes]);

  //是否展示回归礼包
  const isShowReturnGift = useMemo(() => {
    if (!detail || !currentId) {
      return false;
    }

    const activityType = detail.item?.activity_type;

    if (activityType !== 5) {
      return false;
    }
    return detail?.item?.id === parseInt(currentId as string) && endTimes > 0;
  }, [detail, currentId, endTimes]);

  //是否展示回归福利
  const isShowReturnFul = useMemo(() => {
    if (!detail || !currentId) {
      return false;
    }
    const activityType = detail.item?.activity_type;
    if (activityType !== 6) {
      return false;
    }
    return detail?.item?.id === parseInt(currentId as string);
  }, [detail, currentId]);

  return isShowReturnGift || isShowReturnFul ? (
    <PartLoading load={isLoading}>
      <div className="return-gift-activity">
        <div className="gift-banner">
          <span className="gift-close">
            <Img
              src={gb_anniu}
              onClick={() => {
                onClose();
                EventGlobalManager.emit(CLOSE_GIFT_EVENT, currentId);
              }}
            />
          </span>
          <LazyImage
            className="w-[95%] mx-[auto] mt-[0.5rem]  mb-[0.48rem min-h-[4rem]"
            src={pic}
            alt="return-gift-banner"
            height="auto"
            divProps={{
              dynamiClassName: "w-[95%] mx-[auto] mt-[0.5rem]  mb-[0.48rem]",
            }}
          />
          <div className="receive-action">
            {activityType === 6 ? (
              <Button styleType="yellow" className="w-[2.75rem]" onClick={onGoEmailDetail}>
                {pickMailBtnText}
              </Button>
            ) : (
              <Button className="w-[2.75rem]" onClick={onBuy}>
                {i18n.common("CURRENCY_SYMBOL")}
                {price}
              </Button>
            )}
          </div>
          {activityType === 5 && (
            <div className="countdown">
              <div className="count-title">
                <span>{i18n.gifts("COUNTDOWN")}</span>
              </div>
              <div className="count-time">
                <EndTime
                  time={endTimes}
                  showMilliseconds={true}
                  endCall={() => {
                    onClose();
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </PartLoading>
  ) : null;
});

ReturnGiftActivity.displayName = "ReturnGiftActivity";

export default ReturnGiftActivity;
