import "./vip.scss";
import VIP_D1 from "./assets/VIP_D1.inline.png";
import VIP_D2 from "./assets/VIP_D2.inline.png";
import VIP_D3 from "./assets/VIP_D3.inline.png";
import VIP_D4 from "./assets/VIP_D4.inline.png";
import VIP_D5 from "./assets/VIP_D5.inline.png";
import VIP_D6 from "./assets/VIP_D6.inline.png";
import VIP_D7 from "./assets/VIP_D7.inline.png";
import VIP_D8 from "./assets/VIP_D8.inline.png";
import VIP_D9 from "./assets/VIP_D9.inline.png";
import VIP_D10 from "./assets/VIP_D10.inline.png";
import VIP_D11 from "./assets/VIP_D11.inline.png";
import VIP_D12 from "./assets/VIP_D12.inline.png";
import topone_VIP_D11 from "./assets/topone_VIP_D11.inline.png";
import topone_VIP_D12 from "./assets/topone_VIP_D12.inline.png";

import NUM_0 from "./assets/num/0.inline.png";
import NUM_1 from "./assets/num/1.inline.png";
import NUM_2 from "./assets/num/2.inline.png";
import NUM_3 from "./assets/num/3.inline.png";
import NUM_4 from "./assets/num/4.inline.png";
import NUM_5 from "./assets/num/5.inline.png";
import NUM_6 from "./assets/num/6.inline.png";
import NUM_7 from "./assets/num/7.inline.png";
import NUM_8 from "./assets/num/8.inline.png";
import NUM_9 from "./assets/num/9.inline.png";
import NUM_V from "./assets/num/v.inline.png";

import { useMemo } from "react";
import Img from "../Img";
import { useImgType } from "@/hooks";

interface VipProps {
  level: number;
}

function Vip(props: VipProps) {
  const { level } = props;
  const { type } = useImgType();

  const NUM_INFO = useMemo(() => {
    let vipList = [VIP_D1, VIP_D1, VIP_D2, VIP_D3, VIP_D4, VIP_D5, VIP_D6, VIP_D7, VIP_D8, VIP_D9, VIP_D10];
    if (type) {
      vipList = [...vipList, topone_VIP_D11, topone_VIP_D12];
    } else {
      vipList = [...vipList, VIP_D11, VIP_D12];
    }

    return {
      vip: vipList[level],
    };
  }, [level]);

  const LevelRender = useMemo(() => {
    const numList = [NUM_0, NUM_1, NUM_2, NUM_3, NUM_4, NUM_5, NUM_6, NUM_7, NUM_8, NUM_9];
    if (level < 10) {
      return <Img noLoad className="vip-level-text-image" src={numList[level] as unknown as string} alt="" />;
    } else {
      const last = level % 10;
      return (
        <>
          <Img noLoad className="vip-level-text-image !max-w-[0.12rem]" src={numList[1] as unknown as string} alt="" />
          <Img noLoad className="vip-level-text-image" src={numList[last] as unknown as string} alt="" />
        </>
      );
    }
  }, [level]);

  return (
    <div className="vip-container">
      <div className="vip-level">
        <Img noLoad src={NUM_INFO.vip as unknown as string} alt="" />
      </div>
      <div className="vip-level-text">
        <Img noLoad className="vip-level-text-image" src={NUM_V as unknown as string} alt="" />
        {LevelRender}
      </div>
    </div>
  );
}

export default Vip;
