"use client";

import "./ProfileWallet.scss";
import { useDispatch } from "react-redux";
import { AuthActions } from "@/redux/features/auth-slice";
import { cdnPrefix, i18n } from "@/utils";
import Link from "next/link";
import Img from "@/components/Img";
import ICON_G_KH from "@/assets/profile/g_kh_icon.inline.png";
import ICON_Download from "@/assets/profile/download-inline.png";
import { ActivityActions } from "@/redux/features/activity-slice";

function ProfileAppDownload() {
  const dispatch = useDispatch();

  return (
    <div className="profile-wallet mt-[.04rem]">
      <Link
        className="profile-wallet-item grid grid-cols-[0.52rem_1fr_0.15rem] gap-[0.15rem]"
        href=""
        onClick={() => {
          dispatch(AuthActions.setAsideAlert(false));
          dispatch(
            ActivityActions.setAppDownload({
              isVisible: true,
            }),
          );
        }}
        prefetch={true}
        replace={true}
      >
        <Img noLoad src={ICON_Download.src} height={"100%"} className="w-[0.52rem]" />
        <span>{i18n.activiy("APK_DOWNLOAD_3")}</span>
        <Img noLoad className="profile-wallet-item-arrow-image" src={ICON_G_KH} alt="g_kh_icon" />
      </Link>
    </div>
  );
}

export default ProfileAppDownload;
