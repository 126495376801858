import { client_proto } from "@/utils/net/shared/proto";
import BrowserWsClient from "@/utils/net/BrowserWsClient";
import { AgentRank } from "@/const";

export namespace AgentRankService {
  const dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_AGENT_GET_RANK;

  const send = (route: string, cmd: number, data: any = null, force = false) => {
    BrowserWsClient.instance.handleSendData({
      route,
      cmd,
      dsttype,
      data,
      force,
    });
  };

  export const getRankShowListResp = () => {
    let cmd = client_proto.AGENT_RANK_INFO_SUB_MSG_ID.AGENT_RANK_MSG_ID_RANK_SHOW_LIST;
    let data = {};
    send(AgentRank.Req.SHOW_LIST, cmd, data);
  };

  export const getRankJackpot = (id: number) => {
    let cmd = client_proto.AGENT_RANK_INFO_SUB_MSG_ID.AGENT_RANK_MSG_ID_RANK_JACKPOT;
    let data = {
      id,
    };
    send(AgentRank.Req.JACKPOT, cmd, data);
  };

  /**
   * 请求排行榜列表
   * @param uid
   * @param id 代理id
   * @param is_history  是否请求历史记录
   * @param page_number 请求的第几页数据
   * @param number_per_page 每一页请求多少数量
   * @param force
   * @returns
   */
  export const getRankList = (
    uid: number,
    id: number,
    is_history: boolean = false,
    page_number: number = 1,
    force: boolean = true,
    number_per_page: number = 30,
  ) => {
    let cmd = client_proto.AGENT_RANK_INFO_SUB_MSG_ID.AGENT_RANK_MSG_ID_RANK_LIST;
    let data = {
      uid,
      id,
      is_history,
      page_number,
      number_per_page,
    };
    send(AgentRank.Req.DATA_LIST, cmd, data, force);
  };

  export const getUserDetails = (uid: number, rankId: number, isHistory: boolean = false) => {
    let cmd = client_proto.AGENT_RANK_INFO_SUB_MSG_ID.AGENT_RANK_MSG_ID_RANK_GET_USER_DETAILS;
    let data = {
      uid: uid,
      id: rankId,
      is_history: isHistory,
    };
    send(AgentRank.Req.GET_DETAILS, cmd, data);
  };
  export const requestRankRewardList = (uid: number) => {
    let cmd = client_proto.AGENT_RANK_INFO_SUB_MSG_ID.AGENT_RANK_MSG_ID_RANK_REWARD_LIST;
    let data = {
      uid,
    };
    send(AgentRank.Req.REWARD_LIST, cmd, data);
  };
  /**
   * 请求排行榜历史报告
   * @param id 排行榜id
   */
  export const requestHistoryReport = (key: string, num: number) => {
    if (key === "") {
      return;
    }
    let cmd = client_proto.AGENT_RANK_INFO_SUB_MSG_ID.AGENT_RANK_MSG_ID_RANK_GET_REPORT;
    let data = {
      number: num,
      key: key,
    };
    // this.send(AgentRankReq.GET_REPORT, cmd, data);
    send(AgentRank.Req.GET_REPORT, cmd, data);
  };
  /**
   * 请求领取奖励
   * @param rewardId  奖励ID
   */
  export const requestGetReward = (uid: number, rewardId: number) => {
    let cmd = client_proto.AGENT_RANK_INFO_SUB_MSG_ID.AGENT_RANK_MSG_ID_RANK_GET_REWARD;
    let data = {
      uid,
      reward_id: rewardId,
    };
    let dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_AGENT_RANK;
    BrowserWsClient.instance.handleSendData({
      route: AgentRank.Req.GET_REWARD,
      cmd,
      dsttype,
      data,
    });
    // this.send(AgentRankReq.GET_REWARD, cmd, data);
  };
}
