"use client";

import React, { createContext, useCallback, useContext, useEffect, useId, useMemo, useRef, useState } from "react";
import "./Select.scss";
import { useMount, useSetState, useUnmount, useUpdateEffect } from "react-use";
import { isArray } from "lodash-es";
import { cdnPrefix } from "@/utils";
import Img from "../Img";

interface SelectProps extends React.HTMLAttributes<HTMLSelectElement> {
  value?: any;
  onChange?: (value: any) => void;
  onClose?: () => void;
}

const SelectContext = createContext<SelectProps>({});

function Select(props: SelectProps) {
  const { value, onChange, children } = props;
  const [open, setOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);
  const wearRef = useRef<HTMLDivElement>(null);

  const [wearPosition, setWearPosition] = useSetState({ top: -1000, left: -1000, width: 0 });
  const id = useId();

  useEffect(() => {
    if (open) {
      const rect = selectRef?.current?.getBoundingClientRect();

      setWearPosition({
        top: rect?.top! + rect?.height! + 5 || 0,
        left: rect?.left! || 0,
        width: rect?.width! || 0,
      });
    } else {
      setWearPosition({
        top: -1000,
        left: -1000,
        width: 0,
      });
    }
  }, [open, setWearPosition]);

  useMount(() => {
    document.body.appendChild(wearRef.current!);
  });

  useUnmount(() => {
    document.getElementById(id)?.remove();
  });

  const contextValue = useMemo(() => {
    return {
      value,
      onChange,
      onClose: () => setOpen(false),
    };
  }, [onChange, value]);

  const nextValue = useMemo(() => {
    if (isArray(children)) {
      return children.find((item) => item.props.value === value)?.props.children;
    } else {
      return (children as any)?.props?.children;
    }
  }, [value, children]);

  return (
    <>
      <div ref={selectRef} className="select">
        <div
          className="min-w-[2rem] grid items-center gap-[0.16rem] grid-cols-[1fr_0.01rem_0.08rem] bg-[#3e121a] rounded-[0.16rem] py-[0.14rem] px-[0.25rem] text-[0.3rem]"
          onClick={() => setOpen(!open)}
        >
          <div className="val">{nextValue}</div>
          <div className="h-full bg-[#330900]"></div>
          <div className="arrow"></div>
        </div>
      </div>
      <div
        id={id}
        ref={wearRef}
        // className="absolute z-[100000]  rounded-[0.16rem] text-[0.3rem] p-[0.16rem] select-option-box"
        className="absolute z-[100000]  text-[0.3rem]  select-option-box"
        style={{ ...wearPosition }}
      >
        <div className="select-option-border">
          <SelectContext.Provider value={contextValue}>{props.children}</SelectContext.Provider>
        </div>
      </div>
    </>
  );
}

interface SelectOptionProps {
  value: any;
  children?: React.ReactNode;
}

function SelectOption(props: SelectOptionProps) {
  const { children } = props;
  const { onChange, onClose, value } = useContext(SelectContext);

  const isSelected = useMemo(() => {
    return (value as string).toLocaleLowerCase() === (props.value as string)?.toLocaleLowerCase();
  }, [value, props.value]);

  return (
    <div
      className="text-white text-[0.22rem] py-[0.16rem] flex items-center gap-[0.16rem] justify-between"
      style={{
        color: isSelected ? "white" : "#6C333D",
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        onChange?.(props.value);
        onClose?.();
      }}
    >
      <span className="text-left">{children}</span>
      {isSelected && (
        <Img src={cdnPrefix("/common/checked.png")} alt="checked" className="w-[0.3rem]  object-contain" />
      )}
    </div>
  );
}

Select.Option = SelectOption;

export default Select;
