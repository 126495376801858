import { Recommend } from "@/const";
import { RecommendAction } from "@/redux/features/recommend-slice";
import { EventGlobalManager } from "@/utils/events";
import { useDispatch } from "react-redux";

export default function useRecommendHooks() {
  const dispatch = useDispatch();

  function onListenSocket() {
    EventGlobalManager.on(Recommend.Resp.FacePull, (rsp: any) => {
      const { data } = rsp;
      console.log("face pull data", data);
      dispatch(RecommendAction.setFacePull(data));
    });
  }

  return {
    onListenSocket,
  };
}
