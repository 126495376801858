import React, { useState, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
dayjs.extend(weekOfYear);
import { StorageData } from "@/utils/net/storage/StorageData";
import { StoreKey } from "@/const";
import { useMount } from "react-use";
import { formatNumber } from "accounting";
import { ActivityService, HallService } from "@/service";
import { useAppSelector } from "@/redux/store";
import { EventGlobalManager } from "@/utils/events";
import { RECEIVE_GIFT_RESULT } from "@/components/RegressActivity";

export const useFacePull = () => {
  const date = dayjs();
  const usr_basic_info = useAppSelector((state) => state?.authSlice?.basic_info);
  const facePull = useAppSelector((state) => state.hallSlice.facePull);
  const asset = useAppSelector((state) => state?.authSlice.asset);
  const isFace = useAppSelector((state) => state.hallSlice.isFace);
  const dateKey = `${date.year()}/${date.month()}`;
  const weekIndex = `${date?.week()}`;
  const dayIndex = `${date.date()}`;
  const [popData, setPopData] = useState<any>();
  const activityList = useAppSelector((state) => state.activitySlice.activityList);

  useEffect(() => {
    if (isFace && usr_basic_info?.uid !== undefined) {
      HallService.FacePullReq(usr_basic_info?.uid!, 1);
    }
  }, [usr_basic_info?.uid, isFace]);

  useMount(() => {
    //回归福利领取成功刷新拍脸图
    EventGlobalManager.on(RECEIVE_GIFT_RESULT, (result: boolean) => {
      if (result) {
        HallService.FacePullReq(usr_basic_info?.uid!, 1);
      }
    });
  });

  useEffect(() => {
    let data = facePull?.data_list?.filter((val) => {
      // 过滤没有次数的数据
      // if (val?.pop_number === 0) return false;
      if (val?.jump_type === 1) {
        return val?.jump !== "";
      } else {
        return val?.pic !== "" && val?.pic?.length! > 0;
      }
    });
    data = data?.filter((item) => {
      const money = asset?.amount! + asset?.coin!;
      const moneyMin = item?.min_coin || -1;
      const moneyMax = item?.max_coin || -1;
      if (money < moneyMin) {
        return false;
      }
      if (moneyMax > 0 && money > moneyMax) {
        return false;
      }
      return true;
    });
    data?.sort((a, b) => {
      return b?.order_id! - a?.order_id!;
    });
    dialogNumber(data);
    setPopData(data);
  }, [facePull, asset?.amount, asset?.coin]);

  const initialValue = () => {
    let storage = JSON.parse(StorageData.local.get(StoreKey.POP_MONTH_COUNT_DATA) as string) || {};
    if (!storage?.hasOwnProperty(dateKey)) {
      storage = {
        [dateKey!]: {},
      };
    }
    if (!storage[dateKey!]?.hasOwnProperty(weekIndex)) {
      storage[dateKey!] = {
        ...storage[dateKey!],
        [weekIndex!]: {},
      };
    }
    if (!storage[dateKey!][weekIndex!]?.hasOwnProperty(dayIndex)) {
      storage[dateKey!][weekIndex!] = {
        ...storage[dateKey!][weekIndex!],
        [dayIndex!]: {
          notCheck: {},
        },
      };
    }
    return storage;
  };

  // 初始化弹窗今日可以展示次数
  const dialogNumber = (data: any) => {
    const storage = initialValue();
    let popMonthCountData = storage;
    data?.forEach((val: any) => {
      if (val?.pop_frequency === 3 && val?.pop_number >= 0) {
        let dateObj = popMonthCountData[dateKey!];
        const face_id = dateObj?.[val.face_id];
        dateObj = {
          ...dateObj,
          [val.face_id]: face_id === undefined ? 0 : face_id,
        };
        popMonthCountData[dateKey!] = dateObj;
      }
      if (val?.pop_frequency === 2 && val?.pop_number >= 0) {
        let weekObj = popMonthCountData[dateKey!][weekIndex!];
        const face_id = weekObj?.[val.face_id];
        weekObj = {
          ...weekObj,
          [val.face_id]: face_id === undefined ? 0 : face_id,
        };
        popMonthCountData[dateKey!][weekIndex!] = weekObj;
      }
      if (val?.pop_frequency === 1 && val?.pop_number >= 0) {
        let dayObj = popMonthCountData[dateKey!][weekIndex!][dayIndex!];
        const face_id = dayObj?.[val.face_id];
        dayObj = {
          ...dayObj,
          [val.face_id]: face_id === undefined ? 0 : face_id,
        };
        popMonthCountData[dateKey!][weekIndex!][dayIndex!] = dayObj;
      }
    });
    StorageData.local.set(StoreKey.POP_MONTH_COUNT_DATA, popMonthCountData);
  };

  // 判断弹窗是否展示
  const checkPopCount = (info: any) => {
    let storage: any = JSON.parse(StorageData.local.get(StoreKey.POP_MONTH_COUNT_DATA) as string);
    const today = dayjs().valueOf();
    if (storage?.[dateKey]?.[weekIndex]?.[dayIndex]?.notCheck?.[info?.face_id]) {
      return false;
    }
    if (info.show_type == 2) {
      // 0/1为永久显示 2为自定义时间
      if (today < info.show_start_time || today >= info.show_end_time) {
        return false;
      }
    }
    if (info?.pop_frequency === 1) {
      let count = storage?.[dateKey]?.[weekIndex]?.[dayIndex]?.[info?.face_id];
      if (count >= info.pop_number && info.pop_number !== 0) {
        return false;
      }
      return true;
    }
    if (info?.pop_frequency === 2) {
      let count = storage?.[dateKey]?.[weekIndex]?.[info?.face_id];
      if (count >= info.pop_number && info.pop_number !== 0) {
        return false;
      }
      return true;
    }
    if (info?.pop_frequency === 3) {
      let count = storage?.[dateKey]?.[info?.face_id];
      if (count >= info.pop_number && info.pop_number !== 0) {
        return false;
      }
      return true;
    }
    return false;
  };

  // 更新弹窗次数
  const updatePopCount = (info: any, notCheck: any = null) => {
    let storage: any = JSON.parse(StorageData.local.get(StoreKey.POP_MONTH_COUNT_DATA) as string);
    if (notCheck !== null) {
      storage[dateKey][weekIndex][dayIndex].notCheck = {
        ...storage?.[dateKey]?.[weekIndex]?.[dayIndex].notCheck,
        [info?.face_id]: notCheck,
      };
    }
    if (info?.pop_frequency === 1) {
      let count = storage?.[dateKey]?.[weekIndex]?.[dayIndex]?.[info?.face_id];
      count = count + 1;
      storage[dateKey][weekIndex][dayIndex][info?.face_id] = count;
    }
    if (info?.pop_frequency === 2) {
      let count = storage?.[dateKey]?.[weekIndex]?.[info?.face_id];
      count = count + 1;
      storage[dateKey][weekIndex][info?.face_id] = count;
    }
    if (info?.pop_frequency === 3) {
      let count = storage?.[dateKey]?.[info?.face_id];
      count = count + 1;
      storage[dateKey][info?.face_id] = count;
    }
    StorageData.local.set(StoreKey.POP_MONTH_COUNT_DATA, storage);
  };
  const data = useMemo(() => {
    return popData?.filter((val: any) => checkPopCount(val));
  }, [popData]);
  return {
    data,
    updatePopCount,
  };
};
