import React from "react";

export namespace InputUtils {
  export function color(val: string): string {
    return val ? "#e6e6e6" : "#a8a2a3";
  }

  export function colorStyle(val: string): React.CSSProperties {
    return { color: color(val) };
  }
}
