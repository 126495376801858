import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/Montserrat-Medium.ttf\",\"display\":\"swap\",\"preload\":true,\"weight\":\"normal\",\"style\":\"normal\"}],\"variableName\":\"myFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/App.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/app/src/app/fonts/Montserrat.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/swiper/modules/pagination.css");
;
import(/* webpackMode: "eager" */ "/app/src/assets/profile/g_tx01.inline.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/profile/g_tx02.inline.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/profile/g_tx03.inline.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/profile/g_tx04.inline.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/profile/g_tx05.inline.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/profile/g_tx06.inline.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/profile/g_tx07.inline.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/profile/g_tx08.inline.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/profile/g_tx09.inline.jpg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/profile/phb_ques.inline.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/widget/TabBar/index.tsx");
