import AlertBody from "@/components/Alert/AlertBody";
import { Alert, AlertHeader } from "@/components/Alert";
import { AuthActions } from "@/redux/features/auth-slice";
import { i18n } from "@/utils";
import { Select } from "@/components/Select";
import { useAppSelector } from "@/redux/store";
import { useDispatch } from "react-redux";
import Img from "@/components/Img";
import { useContext, useMemo, useState } from "react";
import SettingContext from "@/service/setting";
import ICON_GBBH from "@/assets/profile/settings/g_bbh_icon.inline.png";
import ICON_BB from "@/assets/profile/settings/g_bb_icon.inline.png";
import { Divider } from "@nextui-org/react";
import { StorageData } from "@/utils/net/storage/StorageData";
import { StoreKey } from "@/const";

function SettingsAlert() {
  const alertGlobState = useAppSelector((state) => state.authSlice.show_profile_alert);
  const imgType = StorageData.local.get(StoreKey.IMG_TYPE, "to");
  const dispatch = useDispatch();

  const { settings, setSettings } = useContext(SettingContext);

  const [type, setType] = useState(imgType);

  return (
    <Alert open={alertGlobState?.settings} onClose={() => dispatch(AuthActions.setSettingsAlert(false))}>
      <AlertHeader title={i18n.profile("LYSETTING_34")} />
      <AlertBody>
        <div className="conta-seguranca">
          <div className="conta-seguranca-item account-bind-item !grid-cols-[0.45rem_1fr]">
            <Img src={ICON_BB} alt="yanzm" />
            <div className="code-container">
              <input className="text-[0.3rem] text-[#c5b9ba]" readOnly value={i18n.profile("LYSETTING_38")} />
              <Select
                value={settings?.language}
                onChange={(language) => {
                  setSettings?.({ language });
                }}
              >
                <Select.Option value="en-In">English</Select.Option>
                <Divider className="bg-[rgb(51,9,0)]" />
                <Select.Option value="pt">Português (idioma)</Select.Option>
              </Select>
            </div>
          </div>
          <div className="conta-seguranca-item account-bind-item !grid-cols-[0.45rem_1fr]">
            <Img src={ICON_GBBH} alt="yanzm" />
            <div className="code-container">
              <input className="text-[0.3rem] text-[#c5b9ba]" readOnly value={i18n.profile("LYSETTING_39")} />
              <span className="text-[0.3rem] text-[#c5b9ba]">1.0.0</span>
            </div>
          </div>
          <div className="conta-seguranca-item account-bind-item !grid-cols-[0.45rem_1fr]">
            <Img src={ICON_GBBH} alt="yanzm" />
            <div className="code-container">
              <input className="text-[0.3rem] text-[#c5b9ba]" readOnly value={"图片切换"} />
              <Select
                value={type}
                onChange={(type) => {
                  setType?.(type);
                  StorageData.local.set(StoreKey.IMG_TYPE, type);
                }}
              >
                <Select.Option value="y1">Y1</Select.Option>
                <Divider className="bg-[rgb(51,9,0)]" />
                <Select.Option value="to">Top One</Select.Option>
              </Select>
            </div>
          </div>
        </div>
      </AlertBody>
    </Alert>
  );
}

export default SettingsAlert;
