import { useAppSelector } from "@/redux/store";
import { useMemo } from "react";

function useAgentRankResp() {
  const agentRankShowListResp = useAppSelector((state) => state?.agentRankSlice?.showListResp);

  const showList = useMemo(() => {
    return agentRankShowListResp.show_list || [];
  }, [agentRankShowListResp.show_list]);

  const agentId = useMemo(() => {
    return showList.length > 0 ? showList.filter((item) => item.is_history === false)?.shift()?.id : 0;
  }, [showList]);

  return {
    showList,
    agentId,
  };
}

export default useAgentRankResp;
