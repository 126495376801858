/** 道具类型定义 */
export const PROP_ID = {
  PROP_NULL: 0,
  /** 充值金 */
  PROP_AMOUNT: 1,
  /** 提现金 */
  PROP_COIN: 2,
};

/** 资产类型（pb定义） */
export const PropertyType = {
  PROP_NULL: 0,
  /** 充值金 */
  PROP_AMOUNT: 1,
  /** 提现金 */
  PROP_COIN: 2,
  /** 总下注 */
  PROP_TOTALBET: 3,
  /** 总充值 */
  PROP_TOTALRECHARGE: 4,
  /** 充值金+提现金 */
  PROP_BALANCE: 5,
  /** 提现限额 */
  PROP_WITHDRAW_LIMIT: 6,
  /** 钻石 */
  PROP_DIAMOND: 7,
  /** 体验币 */
  PROP_EXP: 8,
  /** 练习币 */
  PROP_PRT: 9,
};

// 资产类型对应assets字段
export const assetsKey: any = {
  "1": "amount",
  "2": "coin",
  "3": "totalbet",
  "4": "totalrecharge",
};

/** 物品来源 */
export const PROP_FROM = {
  /** 常规操作 刷新资产 */
  FRESH: 0,
  /** 首充 */
  RECHARGE: 1,
  /** 累充 */
  ACCUMULATED: 2,
  /**站内信息-系统 */
  EMAIL_PLATFORM: 3,
  /**站内信息-个人 */
  EMAIL_PERSONAL: 4,
  /** 转盘提现 */
  SPIN_WITHDRAW: 5,
  /** 钱包充值 */
  WALLET_RECHARGE: 6,
  /** 钱包提现 */
  WALLET_WITHDRAW: 7,
  /** VIP福利 */
  VIP_WELFARE: 8,
  /** 佣金 */
  COMMISSIONS: 9,
  /** 邀请任务活动 */
  INVITE_ATIVITE: 10,
  /** 兑换码奖励 */
  GIFT: 11,
  /** vip下注返水 */
  VIP_BET_REDATE: 12,
  /** 活动商品获得 */
  ACTIVITY_GIFT: 13,
};
