import AlertBody from "@/components/Alert/AlertBody";
import { Alert, AlertHeader } from "@/components/Alert";
import { AuthActions } from "@/redux/features/auth-slice";
import { cdnPrefix, i18n } from "@/utils";
import { useAppSelector } from "@/redux/store";
import { useDispatch } from "react-redux";
import { useInterval, useSetState } from "react-use";
import { GameService, UserService } from "@/service";
import { useContext, useState } from "react";
import { InputUtils } from "@/utils/input";
import { StoreKey } from "@/const";
import { get } from "lodash-es";
import Img from "@/components/Img";

import ICON_SJ from "@/assets/profile/seguranca/sj.inline.png";
import ICON_ST from "@/assets/profile/seguranca/st.inline.png";
import { Button } from "@/components/Button";
import toast from "../Toast";
import SettingContext from "@/service/setting";

function BindPhoneCompleteAlert() {
  const show_profile_alert = useAppSelector((state) => state.authSlice.show_profile_alert);
  const user_basic_info = useAppSelector((state) => state.authSlice.basic_info);
  const dispatch = useDispatch();

  const { settings } = useContext(SettingContext);

  const [seconds, setSeconds] = useState(0);

  const [form, setForm] = useSetState<{
    phone: string;
    code: string;
  }>({
    phone: "",
    code: "",
  });

  const onSendSmsCode = () => {
    if (!form.phone) {
      toast(i18n.login("LYSETTING_53")); // 提示用户手机号码不能为空
      return;
    }

    const maxLen = settings?.language === "pt" ? 11 : 10;

    if (form.phone.length! < maxLen) {
      toast(i18n.login("LYSETTING_53")); // 提示用户手机号码不能为空
      return;
    }

    UserService.sendSmsCode(form.phone).then((res) => {
      if (res?.code === 0) {
        setSeconds(60);
        toast(i18n.login("LYSETTING_12"));
      } else {
        toast(res?.msg);
      }
    });
  };

  const onBindPhone = () => {
    UserService.guestBinding({
      code: form.code,
      phone: form.phone,
      uid: user_basic_info?.uid!,
    }).then((res) => {
      if (res.msg) {
        toast(res.msg);
      }
      if (res.code === 0) {
        dispatch(AuthActions.setBindPhoneCompleteAlert(false));
        const config = GameService.getConfig();
        UserService.getUserInfo(get(config, StoreKey.USER_TOKEN), get(config, StoreKey.CLIENT_IP));
      }
    });
  };

  useInterval(
    () => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    },
    seconds > 0 ? 1000 : null,
  );

  return (
    <Alert
      open={show_profile_alert?.bind_phone_complete}
      onClose={() => {
        dispatch(AuthActions.setBindPhoneAlert(false));
        dispatch(AuthActions.setBindPhoneCompleteAlert(false));
      }}
      outsideClose={false}
      showMark={false}
    >
      <AlertHeader title={i18n.profile("LYSETTING_44")} />
      <AlertBody>
        <div className="conta-seguranca">
          <div className="conta-seguranca-item account-bind-item">
            <Img src={ICON_SJ} alt="sj" />
            <div className="conta-seguranca-item-phone">
              <span className="area-code">{i18n.login("PHONE_AREA_CODE")}</span>
              <span className="line" />
              <input
                type="number"
                style={{ ...InputUtils.colorStyle(form.phone), resize: "none" }}
                value={form.phone}
                placeholder={i18n.login("LYSETTING_48")}
                onInput={(e: any) => {
                  const reg = /\D/g;
                  const value = e?.target?.value?.replace(reg, "");
                  e.target.value = value;
                }}
                onChange={(e) => setForm({ phone: e.target.value.slice(0, 11) })}
              />
            </div>
          </div>
          <div className="conta-seguranca-item account-bind-item">
            <Img src={cdnPrefix("profile/seguranca/yanzm.png")} alt="yanzm" />
            <div className="code-container">
              <input
                style={{ ...InputUtils.colorStyle(form.code) }}
                value={form.code}
                placeholder={i18n.profile("LYSETTING_49")}
                onChange={(e) => setForm({ code: e.target.value })}
                className="truncate"
              />
              {seconds === 0 && (
                <Button
                  className="!h-[.5rem] !leading-[.1rem] !text-[.24rem] !text-[rgba(255,255,255,.6)]"
                  styleType="red"
                  onClick={onSendSmsCode}
                >
                  {i18n.profile("LYSETTING_52")}
                </Button>
              )}
              {seconds > 0 && <span className="code-time text-[0.28rem]">{seconds}</span>}
            </div>
          </div>
        </div>
        <div className="bind-count-number-description">{i18n.profile("LYSETTING_51")}</div>
        <div className="anniu-xiao">
          <Button styleType="default" onClick={onBindPhone} className="!text-[.26rem] !min-w-[2.7rem]">
            <span>{i18n.profile("LYSETTING_50")}</span>
          </Button>
        </div>
      </AlertBody>
    </Alert>
  );
}

export default BindPhoneCompleteAlert;
