import { useAppSelector } from "@/redux/store";
import { formatNumber } from "accounting";
import { memo } from "react";

const format = (total: number) => {
  let truncatedNum: string | number = total / 1000;
  //如果是整数
  if (Number.isInteger(truncatedNum)) {
    return formatNumber(truncatedNum);
  }
  const pos = truncatedNum.toString().indexOf(".");
  truncatedNum = Math.floor(truncatedNum) + "." + truncatedNum.toString().slice(pos + 1, pos + 3);
  return formatNumber(Number(truncatedNum), 2);
};

const UserAmount = memo(() => {
  const asset = useAppSelector((state) => state?.authSlice.asset);

  return asset && <>{format(asset?.amount! + asset?.coin!)}</>;
});

UserAmount.displayName = "UserAmount";

export default UserAmount;
