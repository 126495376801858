import React, { useEffect, useRef } from "react";
import gb_anniu from "@/assets/common/gb_anniu.inline.png";

import "./index.scss";
import classNames from "classnames";
import Img from "../Img";

interface AlertProps {
  children: React.ReactNode;
  open?: boolean;
  isLong?: boolean;
  onClose?: () => void;
  outsideClose?: boolean;
  zIndex?: number;
  showMark?: boolean;
  className?: any;
}

function Alert(props: AlertProps) {
  const { children, open, onClose, isLong = false, outsideClose = true, zIndex, showMark = true, className } = props;
  const alertRef = useRef<HTMLDivElement>(null);

  const alertStyle = {
    width: isLong ? "7.12rem" : "6.36rem",
  };

  // 解决移动端滑动时候背景内容跟着滑动的问题
  useEffect(() => {
    const body = document.querySelector("body");
    if (open) {
      body?.classList.add("body-no-scroll");
    } else {
      body?.classList.remove("body-no-scroll");
    }
  }, [open]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (outsideClose && alertRef.current && !alertRef.current.contains(event.target as Node)) {
        if (onClose) {
          onClose();
        }
      }
    };
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose, outsideClose]);

  return (
    open && (
      <div
        className={classNames([
          "common-alert",
          {
            "fadein-32ms-linear": open,
          },
        ])}
        style={{
          zIndex: zIndex ?? 1001,
          backgroundColor: showMark ? undefined : "transparent",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          className={classNames("common-alert-container min-h-[5.73rem]", className)}
          style={alertStyle}
          ref={alertRef}
        >
          <div className="common-alert-close" onClick={onClose}>
            <Img src={gb_anniu} className="max-w-[0.31rem]" />
          </div>
          {children}
        </div>
      </div>
    )
  );
}

export default Alert;
