import React, { useRef, useState } from "react";
import { useUpdateEffect } from "react-use";

function useLoadState(
  init: boolean = false,
  daly: number = 5000,
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
  const [loading, setLoading] = useState(init);
  const timeRef = useRef<NodeJS.Timeout | null>(null);

  useUpdateEffect(() => {
    if (loading) {
      if (timeRef.current) {
        clearTimeout(timeRef.current);
      }
      timeRef.current = setTimeout(() => {
        setLoading(false);
      }, daly);
    } else {
      if (timeRef.current) {
        clearTimeout(timeRef.current);
      }
    }
  }, [loading]);

  return [loading, setLoading];
}

export default useLoadState;
