import { User } from "@/const";
import { EventGlobalManager } from "@/utils/events";
import { agent } from "@/utils/net/shared/proto";
import { memo, useEffect, useState } from "react";
import { useMount } from "react-use";
import { Alert, AlertHeader } from "../Alert";
import AlertBody from "../Alert/AlertBody";
import { Button } from "../Button";
import { i18n } from "@/utils";
import { useRouter } from "next/navigation";
import { UserService } from "@/service";
import { useDispatch } from "react-redux";
import { ActivityActions } from "@/redux/features/activity-slice";
import { useAppSelector } from "@/redux/store";
import { AuthActions } from "@/redux/features/auth-slice";

const KickPush = memo(() => {
  const [isShowKickModal, setIsShowKickModal] = useState(false);
  const usr_basic_info = useAppSelector((state) => state.authSlice.basic_info);
  const kickpush = useAppSelector((state) => state.authSlice.kickpush);
  const { push } = useRouter();
  const dispatch = useDispatch();
  const onConfirm = () => {
    setIsShowKickModal(false);
    push("/login");
  };

  useEffect(() => {
    if (kickpush && kickpush.uid && usr_basic_info?.uid) {
      if (kickpush.uid === usr_basic_info?.uid) {
        setIsShowKickModal(true);
        dispatch(ActivityActions.setShowRegressActivty(null));
        UserService.onLogout();
        dispatch(AuthActions.setKickPush(null));
      }
    }
  }, [usr_basic_info, kickpush]);

  return (
    <Alert open={isShowKickModal} onClose={onConfirm} className="kickpush-modal" zIndex={10004}>
      <AlertHeader title={i18n.common("WARNING_TIME")}></AlertHeader>
      <AlertBody>
        <div className="text-[#DCDCDC] text-center py-[1.4rem] text-[.26rem]">{i18n.home("SAMELESS_8")}</div>
        <div className="text-center">
          <Button className="w-[2.64rem] mx-auto" onClick={onConfirm}>
            {i18n.common("BTN_CONFIRM")}
          </Button>
        </div>
      </AlertBody>
    </Alert>
  );
});

KickPush.displayName = "KickPush";

export default KickPush;
