import { VIP } from "@/const";
import { VipActions, VipData } from "@/redux/features/vip-slice";
import { EventGlobalManager } from "@/utils/events";
import { NetMsg } from "@/utils/net/INet";
import { client_proto } from "@/utils/net/shared/proto";
import { useDispatch } from "react-redux";
import { get } from "lodash-es";

export default function useVipHooks() {
  const dispatch = useDispatch();

  const onListenSocket = () => {
    EventGlobalManager.on(VIP.Resp.PAGE_DATA, (rsp: any) => {
      const data = rsp?.data as VipData;
      dispatch(VipActions.setVipConfigInfo(data));
    });

    EventGlobalManager.on(VIP.Resp.REWARD, (rsp: NetMsg<client_proto.ISetVipPrivilegeResp>) => {
      const data = get(rsp, "data");
      dispatch(VipActions.setVipPrivilege(data));
    });

    EventGlobalManager.on(VIP.Resp.HISTORY, (rsp: NetMsg<client_proto.IGetVipDetailLogResp>) => {
      const data = get(rsp, "data");
      dispatch(VipActions.setVipHistory(data));
    });

    EventGlobalManager.on(VIP.Resp.DETAIL, (res: NetMsg<client_proto.IVipLevelDescResp>) => {
      const data = get(res, "data");
      dispatch(VipActions.setVipLevelDesc(data));
    });

    EventGlobalManager.on(VIP.Resp.VIP_INFO, (res: NetMsg<client_proto.IGetUserVipResp>) => {
      const data = get(res, "data");
      dispatch(VipActions.setUserVipResp(data));
    });
    EventGlobalManager.on(VIP.Resp.REWARD, (res: NetMsg<client_proto.IGetUserVipResp>) => {
      const data = get(res, "data");
      dispatch(VipActions.setReward(data));
    });
  };

  return {
    onListenSocket,
  };
}
