"use client";
import { toggleAside } from "@/redux/features/aside-slice";
import { useAppSelector } from "@/redux/store";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import Link from "next/link";
// import styles from "./Aside.module.scss";
import "./page.css";

export const ImgPath = "https://static.y1.com/";
import { cdnPrefix, getAvatarUrl, i18n } from "@/utils";
import { formatNumber } from "accounting";
import { AuthActions } from "@/redux/features/auth-slice";
import copyTextToClipboard from "@uiw/copy-to-clipboard";
import toast from "../Toast";
import { imageLoadFadeIn } from "@/utils";
import Img from "@/components/Img";
import { useLoginInterceptor } from "@/components/RouterGuard/login.guard";
import ICON_FZ from "@/assets/common/fz.inline.png";
import ICON_NV from "@/assets/profile/g_nv.inline.png";
import ICON_NAN from "@/assets/profile/g_nan.inline.png";
import { LazyImage } from "@/components/LazyloadImage";
import { useEffect } from "react";
import UserAmount from "@/components/common/UserAmount";
import { ActivityActions } from "@/redux/features/activity-slice";

function Aside() {
  const isAsideOpen = useAppSelector((state) => state.asideSlice.isAsideOpen);
  const usr_basic_info = useAppSelector((state) => state.authSlice.basic_info);
  const asset = useAppSelector((state) => state.authSlice.asset);
  const multiLanguageGameGroupV1 = useAppSelector((state) => state?.teamSlice?.multiLanguageGameGroupV1);

  const distPatch = useDispatch();

  const { isGuest } = useLoginInterceptor();

  const onCloseAside = () => {
    distPatch(toggleAside(false));
  };

  const onShowAppDownload = (e: any) => {
    distPatch(
      ActivityActions.setAppDownload({
        isVisible: true,
      }),
    );
    onCloseAside();
  };

  useEffect(() => {
    console.log(
      "multiLanguageGameGroupV1?.categoryList",
      multiLanguageGameGroupV1,
      multiLanguageGameGroupV1?.categoryList,
    );
  }, [multiLanguageGameGroupV1]);

  return (
    <aside>
      <div className={classNames("aside-mark", { hidden: !isAsideOpen })} onClick={onCloseAside}></div>
      <div
        className={classNames(
          "linearG aside-content",
          "fixed",
          "h-screen",
          "left-0 top-0",
          "z-[101]",
          "backdrop-blur-md",
          "transition transform duration-300 ease-in-out",
          {
            "-translate-x-full": !isAsideOpen,
          },
        )}
      >
        <div className="user-container grid grid-cols-[1fr_0.1rem] gap-[0.16rem]">
          <div className="left-user-container">
            <div className="user-profile-picture ">
              <div className="head-box" onClick={() => distPatch(AuthActions.setAvatarAlert(true))}>
                <Img
                  // src={cdnPrefix(`/profile/g_tx0${usr_basic_info?.user_avatar}.jpg`)}
                  src={getAvatarUrl(usr_basic_info?.user_avatar)}
                  height={"100%"}
                  className="profile-picture-img"
                />
              </div>

              <div className="profile-picture-camera">
                <LazyImage src={cdnPrefix("/gameList/camera.png")} height={"100%"} />
              </div>
            </div>

            <div className="user-information">
              <div className="name-sex grid grid-cols-[1fr_0.3rem]">
                <div className="truncate text-white">{isGuest ? "--" : usr_basic_info?.user_name}</div>
                {!isGuest && (
                  <Img
                    className="max-w-[0.26rem] max-h-[0.26rem] ml-[0.06rem]"
                    src={Number(usr_basic_info?.user_gender) === 1 ? ICON_NAN : ICON_NV}
                    alt="sex boy"
                  />
                )}
              </div>
              <p className="user-identification">
                ID:
                <span>{isGuest ? "--" : usr_basic_info?.uid}</span>
                {!isGuest && (
                  <Img
                    className="max-w-[0.26rem] max-h-[0.25rem] inline-block ml-[0.1rem]"
                    src={ICON_FZ}
                    alt="fz"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      copyTextToClipboard(usr_basic_info?.uid?.toString()!, (isCopy) => {
                        if (isCopy) {
                          toast(`${i18n.common("COPIADO_INFO_SUFCCS")}`);
                        } else {
                          toast(`${i18n.common("COPIADO_INFO_ERRO")}`);
                        }
                      });
                    }}
                  />
                )}
              </p>
              <p className="user-fraction">
                {i18n.common("CURRENCY_SYMBOL")} {isGuest ? "--" : <UserAmount />}
              </p>
            </div>
          </div>
          {isGuest ? null : (
            <div
              className="user-rightIcon"
              onClick={() => {
                distPatch(toggleAside(false));
                distPatch(AuthActions.setAsideAlert(true));
              }}
            >
              <LazyImage src={cdnPrefix("/gameList/fgx_JT.png")} height={"100%"} className="rightIcon" />
            </div>
          )}
        </div>
        <nav>
          <ul className="menu-style">
            <li
              onClick={(e) => e.stopPropagation()}
              key={"HOME"}
              className={classNames("HOME" == "HOME" ? "active" : "", "menu-li-style sticky top-0 z-10")}
            >
              <Link
                prefetch={true}
                href={"/"}
                className="flex  justify-between  items-center btn_animation"
                onClick={onCloseAside}
              >
                <div className="menu-icon">
                  <LazyImage src={cdnPrefix("gameList/meunIcon/HOME.png")} height={"100%"} />
                </div>
                <h1 className={classNames("menuTitle flex-1 ")}>HOME</h1>
                <div className="float-right right-icon">
                  <LazyImage src={cdnPrefix("/gameList/fgx_JT.png")} height={"100%"} className="rightIcon" />
                </div>
              </Link>
            </li>

            {multiLanguageGameGroupV1?.categoryList?.map(({ id, name, icon, cover }) => (
              <li
                onClick={(e) => e.stopPropagation()}
                key={name}
                className={classNames(name == "HOME" ? "active" : "", "menu-li-style")}
              >
                <Link
                  replace={true}
                  prefetch={true}
                  href={`/gameList?gametype=${id}`}
                  className="flex  justify-between  items-center btn_animation"
                  onClick={onCloseAside}
                >
                  {icon && (
                    <div className="menu-icon">
                      <LazyImage src={icon} height={"100%"} />
                    </div>
                  )}

                  <h1 className={classNames("menuTitle flex-1 ")}>{name}</h1>
                  <div className="float-right right-icon">
                    <LazyImage src={cdnPrefix("/gameList/fgx_JT.png")} height={"100%"} className="rightIcon" />
                  </div>
                </Link>
              </li>
            ))}
            <li className="menu-li-style">
              <Link
                prefetch={true}
                href="/activity"
                className="flex justify-between items-center"
                onClick={onCloseAside}
              >
                <LazyImage src={cdnPrefix("home/PROMOTION.png")} height={"100%"} className="menu-icon" />
                <h1 className={classNames("menuTitle flex-1 ")}>Promoti</h1>
                <div className="float-right right-icon">
                  <LazyImage src={cdnPrefix("/gameList/fgx_JT.png")} height={"100%"} className="rightIcon" />
                </div>
              </Link>
            </li>
            <li className="menu-li-style link-img ">
              <div className="depostie-aside">
                <Link
                  prefetch={true}
                  replace={true}
                  href="/shopping"
                  className="flex justify-between items-center ml-[0.4rem]"
                  onClick={onCloseAside}
                >
                  <LazyImage src={cdnPrefix("profile/qb.png")} height={"100%"} className="menu-icon" />
                  <h1 className={classNames("menuTitle flex-1 ")}>{i18n.home("WALLET_DEPSIT_TEXT")}</h1>
                  <div className="float-right right-icon">
                    <LazyImage src={cdnPrefix("/gameList/fgx_JT.png")} height={"100%"} className="rightIcon" />
                  </div>
                </Link>
              </div>
            </li>
            {/* app下载 */}
            <li className="menu-li-style link-img !mt-[0.04rem]">
              <div className="depostie-aside">
                <Link
                  prefetch={true}
                  replace={true}
                  href=""
                  className="flex justify-between items-center ml-[0.4rem]"
                  onClick={onShowAppDownload}
                >
                  <LazyImage src={cdnPrefix("profile/download.png")} height={"100%"} className="menu-icon" />
                  <h1 className={classNames("menuTitle flex-1 ")}>{i18n.activiy("APK_DOWNLOAD_3")}</h1>
                  <div className="float-right right-icon">
                    <LazyImage src={cdnPrefix("/gameList/fgx_JT.png")} height={"100%"} className="rightIcon" />
                  </div>
                </Link>
              </div>
            </li>
          </ul>
        </nav>

        <div
          className={classNames(
            {
              "left-0": !isAsideOpen,
            },
            "menuRightIcon absolute rotate-180 flex items-center justify-center",
          )}
          style={{ backgroundImage: `url(${cdnPrefix("common/asider_bg.png")})` }}
          onClick={onCloseAside}
        >
          <Img src={cdnPrefix("profile/g_zd_icon.png")} alt="profile/g_zd_icon.png" className="max-w-[0.2rem]" />
        </div>
      </div>
    </aside>
  );
}

export default Aside;
