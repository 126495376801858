"use client";
import "./index.css";
import { MouseEvent, useCallback, useMemo, useState, useRef, useEffect } from "react";
import { usePathname, useRouter } from "next/navigation";
import { useDispatch } from "react-redux";
import { AuthActions } from "@/redux/features/auth-slice";
import { cdnPrefix, i18n } from "@/utils";
import { toggleAside } from "@/redux/features/aside-slice";
import { SpinActions } from "@/redux/features/spin-slice";
import { SpinService } from "@/service";
import { useAppSelector } from "@/redux/store";
import { useMount } from "react-use";
import Link from "next/link";
import useAcNum from "@/hooks/acNum";
import { getNowVipReward } from "@/app/home/Reward/helper";
import Img from "@/components/Img";
import { formatNumber } from "accounting";
import localFont from "next/font/local";
const BoldItalic = localFont({
  src: "../../app/fonts/Montserrat-BoldItalic.ttf",
  display: "swap",
  preload: true,
  weight: "normal",
  style: "normal",
});
import { LazyImage } from "@/components/LazyloadImage";
import classNames from "classnames";
import { pngList } from "./png";
import { useImgType } from "@/hooks";

const tabs: any = [
  {
    label: i18n.home("TABBAR_MENU"),
    value: "menu",
    icon: pngList.cc_dt_tb_1,
    activeIcon: pngList.cc_dt_tb_1_1,
    style: {
      width: ".66rem",
    },
  },
  {
    label: i18n.home("TABBAR_CONTESTAR"),
    value: "contestar",
    icon: pngList.cc_dt_tb_2,
    activeIcon: pngList.cc_dt_tb_2_1,
    topOneActiveIcon: pngList.topone_cc_dt_tb_2_1,
    style: {
      width: "1.29rem",
    },
  },
  {
    label: "10,000",
    value: "10,000",
    icon: "cc_dt_zp.png",
    activeIcon: "cc_dt_zp.png",
  },
  {
    label: i18n.home("TABBAR_VIP"),
    value: "vip",
    icon: pngList.cc_dt_tb_3,
    activeIcon: pngList.cc_dt_tb_3_1,
    style: {
      width: ".39rem",
    },
  },
  {
    label: i18n.home("TABBAR_GANHAR"),
    value: "Ganhar",
    icon: i18n._lane === "pt" ? pngList.cc_dt_tb_4 : pngList.en_cc_dt_tb_4,
    activeIcon: pngList.cc_dt_tb_4_1,
    style: {
      width: ".94rem",
    },
  },
];

enum Pathname {
  Index = "/",
  Home = "/home",
  Ranking = "/ranking",
  Turntable = "/turntable",
  Vip = "/vip",
  Earn = "/earn",
  GameList = "/gameList",
  TurntableHistory = "/turntable/history",
}

const WhiteList = Object.values(Pathname);

const Tarbar = () => {
  const curData = useAppSelector((state) => state.spinSlice.curData);
  const add = useAppSelector((state) => state.spinSlice.add);
  const vip_config_info = useAppSelector((state) => state.vipSlice.vipConfigInfo);
  const pathname = usePathname();

  const [activeIndex, setActiveIndex] = useState(0);
  const { rankNum } = useAcNum();

  const distPatch = useDispatch();
  const acNum = rankNum();
  const { type } = useImgType();

  useEffect(() => {
    if (add?.cur_amount > curData?.amount) {
      SpinService.reqCurData();
      distPatch(AuthActions.setTurntableAlert(true));
      distPatch(SpinActions.setAdd({}));
    }
  }, [add]);

  useEffect(() => {
    const pathname = location.pathname;
    let newActiveIndex = 0;
    switch (pathname) {
      case Pathname.Index:
        newActiveIndex = 0;
        break;
      case Pathname.Ranking:
        newActiveIndex = 1;
        break;
      case Pathname.Turntable:
      case Pathname.TurntableHistory:
        newActiveIndex = 2;
        break;
      case Pathname.Vip:
        newActiveIndex = 3;
        break;
      case Pathname.Earn:
        newActiveIndex = 4;
        break;
      default:
        setActiveIndex(0);
    }
    if (newActiveIndex === activeIndex) {
      return;
    }
    setTimeout(() => {
      const tabItem = document.querySelector(`.footer-tarbar-${newActiveIndex}`);
      if (tabItem) {
        tabItem.className += " active";
      }
    }, 10);
    setActiveIndex(newActiveIndex);
  }, [pathname]);
  const getAmount = (amount: string) => {
    const arr = amount?.split("");
    return arr?.map((val: string, index) => <Img src={cdnPrefix(`/home/number/zp_${val}.png`)} alt="" key={index} />);
  };

  const newTab = useMemo(() => {
    const nextTabs = [...tabs];
    const amount = Math.round(Math.floor(curData?.target / 1000)).toString();
    nextTabs[2].label = getAmount(amount);
    nextTabs[2].value = `Get${i18n.common("CURRENCY_SYMBOL")}${amount || "0"}`;
    nextTabs[2].number = 0;
    if (nextTabs[2].value) {
      const extractNumber = (value: string): number | null => {
        const match = value.match(/(\d+)/); // 匹配数字部分
        return match ? parseInt(match[0], 10) : null; // 返回数字，若未找到则返回 null
      };
      nextTabs[2].number = extractNumber(nextTabs[2].value);
    }
    return nextTabs;
  }, [curData?.target]);

  const getLinkHref = (index: number) => {
    if (index === 1) {
      return "/ranking";
    } else if (index === 2) {
      return "/turntable";
    } else if (index === 3) {
      return "/vip";
    } else if (index === 4) {
      return "/earn";
    } else {
      return "";
    }
  };

  const handleClick = (index: number) => (e: MouseEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (index === 0) {
      distPatch(toggleAside(true));
      return;
    }
  };

  const shouldShowMarkings = (index: number) => {
    if (index === 1) {
      return acNum;
    } else if (index === 3) {
      const { upgradeList } = getNowVipReward(vip_config_info);
      return upgradeList?.length;
    }
  };

  const ref = useRef<any>();
  const turntable = useAppSelector((state) => state?.authSlice?.show_profile_alert?.turntable_alert);
  const amount = useMemo(() => Math.round(Math.floor(curData?.amount / 1000)).toString(), [curData]);
  const percentagem = useMemo(
    () => Math.round(Math.floor((curData?.amount / curData?.target) * 100)).toString(),
    [curData],
  );
  const status = useMemo(() => curData?.amount > 0 && curData?.amount >= curData?.target, [curData]);

  useEffect(() => {
    if (turntable === false || status === false) return;
    distPatch(AuthActions.setTurntableAlert(status));
  }, [distPatch, status, turntable]);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (!ref?.current?.contains(e?.target)) {
        distPatch(AuthActions.setTurntableAlert(false));
      }
    });
    return () => {
      document.removeEventListener("click", () => {});
    };
  }, [distPatch]);

  const [bgImage, setBgImage] = useState<string>(cdnPrefix("/common/cc_dt_di_y1.png"));

  useEffect(() => {
    fetch(cdnPrefix("/home/cc_yj_di.png"))
      .then((res) => res.blob())
      .then((blob) => {
        setBgImage(URL.createObjectURL(blob));
      });
  }, []);

  const click = (e: any) => {
    e.stopPropagation();
  };

  const hidden = useMemo(() => {
    return !WhiteList.includes(pathname as Pathname);
  }, [pathname]);

  return (
    <div hidden={hidden} className="z-100 fixed bottom-0 left-0 w-full">
      {turntable && (
        <div ref={ref} className="footer-turntable-dialog">
          <p className=" mb-[0.15rem]">
            {i18n.spin("WHEEL_CURRENT_TITLE")} <span>{percentagem}%</span>
          </p>
          <div>
            <Img style={{ width: `${percentagem}%` }} src={cdnPrefix("/turntable/jdt_02.png")} alt="" />
          </div>
          <p className="mt-[0.1rem]">
            {i18n.spin("WHEEL_GETTING_TITLE")}{" "}
            <span className="whitespace-pre">
              {i18n.common("CURRENCY_SYMBOL")}
              {amount}
            </span>
          </p>
          <span className="arrow-icon arrow-1"></span>
          <span className="arrow-icon arrow-2"></span>
        </div>
      )}
      <div className="footer-tabbar z-20 max-w-[7.68rem] mx-auto" style={{ backgroundImage: `url(${bgImage})` }}>
        {newTab.map((tab, index) => {
          const render = (
            <>
              {shouldShowMarkings(index) ? (
                <div className="absolute w-[0.10rem] h-[0.10rem] rounded-full bg-[rgb(255,40,80)] top-0 right-[0.2rem]"></div>
              ) : null}
              {index !== 2 && (
                <div className="icon btn_animation">
                  <Img
                    height={"0.7rem"}
                    src={activeIndex === index ? tab.activeIcon : tab.icon}
                    isTO={true}
                    toUrl={activeIndex === index ? tab?.topOneActiveIcon : tab.icon}
                    alt=""
                  />
                </div>
              )}

              {index === 2 ? (
                <>
                  {/* <div className="dynamic-effect" ref={divRef} onClick={click}></div> */}
                  <Link href={getLinkHref(index)} key={index} prefetch={true} replace={true}>
                    <Img className="btn_animation m-[auto]" src={cdnPrefix(`/home/cc_dt_zp.png`)} alt="" isTO={true} />
                  </Link>
                  <div className="text">
                    <span className={BoldItalic.className} data-title={`Get${i18n.common("CURRENCY_SYMBOL")}`}>
                      Get{i18n.common("CURRENCY_SYMBOL")}
                    </span>
                    <span className={BoldItalic.className} data-title={formatNumber(tab.number)}>
                      {formatNumber(tab.number)}
                    </span>
                  </div>
                </>
              ) : (
                <div className="text-icon" data-title={tab.label}>
                  {/* <Img src={textIconSrc} alt="" style={tab.style} /> */}
                  {tab.label}
                </div>
              )}
            </>
          );

          return (
            <div
              key={index}
              className={classNames(
                "footer-tabbar-item " + `footer-tarbar-${index} ` + "relative",
                index === activeIndex ? " active" : "",
              )}
              onClick={handleClick(index)}
            >
              {[0, 2]?.includes(index) ? (
                render
              ) : (
                <Link href={getLinkHref(index)} key={index} prefetch={true} replace={true}>
                  {render}
                </Link>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Tarbar;
