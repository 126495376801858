import styles from "./loading.module.scss";
import classNames from "classnames";

import ICON_LOADING from "@/assets/common/laoding.inline.png";
import TOPONE_LOADING from "@/assets/common/topone_loading.inline.png";
import Img from "@/components/Img";
import { useMemo } from "react";
import { useAppSelector } from "@/redux/store";
import { useImgType } from "@/hooks";
import toast from "@/widget/Toast";
import { i18n } from "@/utils";

interface LoadingProps {
  loading: boolean;
  children?: React.ReactNode;
  zIndex?: number;
  onClose?: () => void;
}

function Loading(props: LoadingProps) {
  const { loading, children, zIndex } = props;
  const weak_network = useAppSelector((state) => state.authSlice.show_profile_alert.weak_network);
  const net_off = useAppSelector((state) => state?.authSlice?.show_profile_alert?.net_error);
  const { type } = useImgType();

  const showLoading = useMemo(() => {
    return loading && !weak_network && !net_off;
  }, [loading, weak_network, net_off]);

  return (
    <div className="flex-auto">
      <div
        className="loading absolute top-0 left-0 bg-black bg-opacity-70 flex justify-center items-center w-full h-full"
        style={{ display: showLoading ? "block" : "none", zIndex: zIndex || 999999 }}
        onClick={() => {
          if (weak_network || net_off) {
            toast(i18n.login("GOOGLE_NET_ERRORS"));
          }
          props.onClose?.();
        }}
      >
        <div className="flex justify-center items-center h-full w-full">
          <div className={"inline-flex justify-center items-center h-[140px] w-[140px]"}>
            <span className={classNames(styles.loader)}></span>
            <Img
              noLoad
              src={type ? TOPONE_LOADING : ICON_LOADING}
              className={classNames("absolute w-[2rem]", styles.rotateZ)}
            />
          </div>
        </div>
      </div>
      {children}
    </div>
  );
}

export default Loading;
