import React from "react";
import classNames from "classnames";
import styles from "../loading.module.scss";

import ICON_LOADING from "@/assets/common/laoding.inline.png";
import TOPONE_LOADING from "@/assets/common/topone_loading.inline.png";
import Img from "@/components/Img";
import { useImgType } from "@/hooks";

type PartLoadingProps = {
  className?: string;
  height?: number | string;
  load?: boolean;
  children?: React.ReactNode;
  fixed?: boolean;
};

function PartLoading({ className, height, load, children, fixed = false }: PartLoadingProps) {
  const { type } = useImgType();
  if (fixed) {
    return (
      <div className={"relative"}>
        {load && (
          <div
            className={classNames("fixed left-0 top-[2rem] flex justify-center items-center h-full w-full", className)}
            style={{ height, zIndex: 9999 }}
          >
            <Img
              noLoad
              src={type ? TOPONE_LOADING : ICON_LOADING}
              className={classNames("absolute w-[2rem]", styles.rotateZ)}
            />
          </div>
        )}
        {children}
      </div>
    );
  }

  if (load === false) {
    return children;
  } else {
    return (
      <div className={classNames("relative flex justify-center items-center", className)} style={{ height }}>
        <div className={"inline-flex justify-center items-center h-[140px] w-[140px]"}>
          <span className={classNames(styles.loader)}></span>
          <Img
            noLoad
            src={type ? TOPONE_LOADING : ICON_LOADING}
            className={classNames("absolute w-[2rem]", styles.rotateZ)}
          />
        </div>
      </div>
    );
  }
}

export default PartLoading;
