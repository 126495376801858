"use client";
import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useFacePull } from "./facePull-hook";
import { cdnPrefix, i18n, styleImage, systemConfigUtils } from "@/utils";
import Img from "@/components/Img";
import { LazyImage } from "@/components/LazyloadImage";
import { usePathname } from "next/navigation";
import { bannerJump, decodeUrl } from "@/components/Banner/utils";
import BannerLink from "@/components/Banner/BannerLink";
import { pngList } from "../png";
import dayjs, { timeRemaining, TimeRemaining } from "@/utils/dayjs";

import "./facePull.css";
import ReturnGiftActivity, { CLOSE_GIFT_EVENT } from "@/components/RegressActivity";
import { ActivityActions } from "@/redux/features/activity-slice";
import { HallActions } from "@/redux/features/hall-slice";
import { ActivityService } from "@/service";
import { useAppSelector } from "@/redux/store";
import { useDispatch } from "react-redux";
import { EventGlobalManager } from "@/utils/events";
import { useBanner } from "@/components/Banner/utils";
import { useInterval, useMount } from "react-use";
import { client_proto } from "@/utils/net/shared/proto";

let hasRegisterClose = false;

export const FacePull = () => {
  const { bannerLinkTo, downloadApk } = useBanner();

  const user_basic_info = useAppSelector((state) => state?.authSlice?.basic_info);
  const activityItem = useAppSelector((state) => state.activitySlice.activityItem);

  const { data, updatePopCount } = useFacePull();
  const [index, setIndex] = useState<any>(null);
  const [isShow, setIsShow] = useState(false);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [isShowTime, setIsShowTime] = useState<boolean>(false);
  const [time, setTime] = useState("");
  const [endTime, setEndTime] = useState<number | null>(null);
  //回归礼包、回归福利的活动id，防止重复弹窗
  const [showRetunGiftFulIds, setShowRetunGiftFulIds] = useState<string[]>([]);
  const [activityItemList, setActivityItemList] = useState<client_proto.IActivityItemPush[]>([]);

  const name = usePathname();

  const dispatch = useDispatch();

  useEffect(() => {
    if (name !== "/") {
      setIndex(null);
    } else {
      if (showRetunGiftFulIds.length !== 0) {
        setShowRetunGiftFulIds([]);
      }
    }
  }, [name]);

  const fetchActivityItemData = (item: any) => {
    const jumpData = decodeUrl(item?.jump);
    if (jumpData.gt_page === "activitygift") {
      ActivityService.reqActivityList(user_basic_info?.uid!, Number(jumpData.gt_params)!);
    }
  };

  const open = () => {
    document.body.style.cssText = "overflow: hidden;";
  };

  const close = () => {
    document.body.style.cssText = "";
  };

  useEffect(() => {
    if (data?.length > 0 && index !== 0 && name === "/") {
      setIndex(0);
      setIsShowDialog(true);
      open();
    }
  }, [data]);

  useEffect(() => {
    const value = data?.[index];
    if (value?.face_id) {
      updatePopCount(value);
      fetchActivityItemData(data?.[index]);
    } else {
      dispatch(HallActions.setFacePull({}));
    }
    setEndTime(null);
  }, [index]);

  useEffect(() => {
    if (!activityItem) {
      return;
    }
    if (activityItemList.findIndex((item) => item.item?.id === activityItem.item?.id) === -1) {
      activityItemList.push(activityItem);
      setActivityItemList([...activityItemList]);
    }
  }, [activityItem]);

  useEffect(() => {
    const value = data?.[index];
    if (value?.face_id) {
      updatePopCount(value, isShow);
    }
  }, [isShow]);

  const timeData = useCallback((value: number) => {
    const data = timeRemaining(value) as TimeRemaining;
    const hours = Number(data?.hours) > 0 ? data?.hours : "00";
    const minutes = Number(data?.minutes) > 0 ? data?.minutes : "00";
    const seconds = Number(data?.seconds) > 0 ? data?.seconds : "00";
    return `${hours}:${minutes}:${seconds}`;
  }, []);

  const closePop = () => {
    setIndex(index + 1);
    setIsShow(false);
    setIsShowDialog(false);
    close();
    setTimeout(() => {
      if (data?.[index + 1]) {
        setIsShowDialog(true);
        open();
      }
    }, 500);
    hasRegisterClose = false;
    EventGlobalManager.off(CLOSE_GIFT_EVENT, closePop);
  };

  const activityJump = (e: any) => {
    const gt_page = decodeUrl(data?.[index]?.jump)?.gt_page || "";
    if (gt_page === "activitygift") {
      e.preventDefault();
      bannerLinkTo(activityItem?.item!);
    } else if (gt_page === "downloadApk") {
      e.preventDefault();
      downloadApk(data?.[index]);
    }
  };

  //是否是回归活动
  const isRegression = useMemo(() => {
    if (!Array.isArray(data) || data.length === 0 || index === null) {
      return false;
    }
    const curData = data?.[index];
    if (!curData) {
      return false;
    }
    const jumpData = decodeUrl(curData?.jump);
    const target = activityItemList.find((item) => item.item?.id === Number(jumpData.gt_params));
    const result = target && [5, 6].includes(target.item?.activity_type!);
    if (result) {
      const jumpData = decodeUrl(data[index].jump);
      let retunGiftId = jumpData.gt_params || "";
      if (retunGiftId && !showRetunGiftFulIds.includes(retunGiftId)) {
        showRetunGiftFulIds.push(retunGiftId);
        setShowRetunGiftFulIds([...showRetunGiftFulIds]);
        dispatch(
          ActivityActions.setShowRegressActivty({
            id: retunGiftId,
            type: 2,
          }),
        );
        EventGlobalManager.on(CLOSE_GIFT_EVENT, closePop);
      } else {
        close();
      }
    }
    return result;
  }, [data?.[index], activityItemList]);

  useInterval(() => {
    if (data?.[index]?.is_show_time !== undefined && data?.[index]?.is_show_time !== 0) {
      const endTime = countTime(data?.[index])!;
      const time = endTime * 1000 - systemConfigUtils?.delayTime;
      setIsShowTime(!!endTime);
      setTime(timeData(time));
    }
  }, 1000);

  const countTime = (curInfo: any) => {
    const isShowTime = curInfo?.is_show_time != 0;
    let allRunTime = 0;
    if (!systemConfigUtils?.delayTime) {
      return allRunTime;
    }
    if (isShowTime) {
      if (curInfo?.count_down_show > 0) {
        allRunTime = Math.floor((systemConfigUtils?.delayTime + curInfo?.count_down_show * 1000) / 1000);
        if (systemConfigUtils?.delayTime && endTime !== null) {
          return endTime;
        } else {
          setEndTime(allRunTime);
          return allRunTime;
        }
      } else {
        if (curInfo.show_type != 2) {
          //永久显示 24小时制度
          let today = systemConfigUtils?.delayTime;
          let date = new Date(today);
          date.setHours(24, 0, 0, 0);
          //结束时间戳 毫秒
          let timestamp = Math.floor(date.getTime() / 1000);

          allRunTime = timestamp;
        } else {
          //2为自定义时间
          let startTime = systemConfigUtils?.delayTime;
          let endTime = curInfo?.show_end_time;
          allRunTime = endTime;
          if (allRunTime < 0) {
            allRunTime = 0;
          }
        }
      }
      return allRunTime;
    } else {
      return 0;
    }
  };
  return (
    <>
      {data?.[index] && (
        <>
          {isRegression ? (
            <></>
          ) : (
            <div className="facePop max-w-[7.68rem]">
              {isShowDialog && (
                <div className="relative top-[50%] translate-y-[-50%] max-h-[100vh]">
                  <div className="mr-[0.5rem] overflow-hidden">
                    <Img className="w-[0.4rem] float-right" src={pngList.gb_anniu} alt="del" onClick={closePop} />
                  </div>
                  <BannerLink prefetch={true} href={bannerJump(data?.[index])} onClick={(e: any) => activityJump(e)}>
                    <LazyImage
                      className="w-[80%] mx-[auto] mt-[0.3rem]  mb-[0.48rem]"
                      src={data?.[index]?.pic}
                      alt="del"
                      height={"auto"}
                      divProps={{
                        dynamiClassName: "w-[95%] mx-[auto] mt-[0.5rem]  mb-[0.48rem]",
                      }}
                    />
                  </BannerLink>
                  {data?.[index]?.jump_button && (
                    <div
                      className="popBtn btn_animation w-[2.75rem] h-[0.64rem] mx-[auto]"
                      style={styleImage("home/cc_yj_can.png")}
                    >
                      <p>{data?.[index]?.jump_button}</p>
                    </div>
                  )}
                  <div
                    className="flex items-center justify-center popCheck mt-[0.18rem] w-[auto] mb-[0.31rem]"
                    onClick={() => setIsShow(!isShow)}
                  >
                    <Img className="w-[0.32rem] mr-[0.22rem]" src={isShow ? pngList.gx_01 : pngList.gx_02} alt="del" />
                    <p>{i18n.home("RECOMMEND_TODAY_NOT_SHOW")}</p>
                  </div>
                  {data?.[index]?.is_show_time !== 0 && (
                    <>
                      <p className="text-[#FED056] text-[0.24rem] h-[0.36rem] flex items-center justify-center">
                        {isShowTime && (
                          <>
                            <span className="bg-[#FED056] h-[0.02rem] w-[0.66rem] block mx-[0.1rem]"></span>
                            {i18n.common("COUNTDOWN")}
                            <span className="bg-[#FED056] h-[0.02rem] w-[0.66rem] block mx-[0.1rem]"></span>
                          </>
                        )}
                      </p>
                      <p className="text-[#FED056] text-[0.36rem] h-[0.36rem] text-center">{isShowTime && time}</p>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};
