import { useAppSelector } from "@/redux/store";
import { cdnPrefix, i18n, isAndroid, isIOS } from "@/utils";
import { StorageData, valueset } from "@/utils/net/storage/StorageData";
import toast from "@/widget/Toast";
import { memo, useEffect, useState } from "react";
import { useMount } from "react-use";
import Img from "../Img";
import closeIcon from "@/assets/activity/gb.inline.png";
import closeIcon2 from "@/assets/activity/gb-2.inline.png";
import "./index.css";
import { useDispatch } from "react-redux";
import { ActivityActions } from "@/redux/features/activity-slice";
import { LazyImage } from "../LazyloadImage";
import "animate.css";
import classNames from "classnames";
import { get } from "lodash-es";

const APP_DOWNLOAD_BUTTON_KEY = "APP_DOWNLOAD_BUTTON_KEY";

enum PlatformType {
  Unknown = 0,
  Android = 1,
  Ios = 2,
}

const AppDownload = () => {
  const [platform, setPlatform] = useState<PlatformType>(0);

  const downloadData = useAppSelector((state) => state.activitySlice.appDownload);

  const [isShowIosModal, setIsShowIosModal] = useState(false);

  const dispatch = useDispatch();

  useMount(() => {
    if (downloadData?.isVisible) {
      dispatch(
        ActivityActions.setAppDownload({
          isVisible: false,
        }),
      );
    }
  });

  useEffect(() => {
    if (downloadData?.isVisible) {
      if (platform == PlatformType.Ios) {
        setTimeout(() => {
          setIsShowIosModal(true);
        }, 100);
      } else if (platform !== PlatformType.Unknown) {
        window.open(downloadData.target || "https://data.t1res.com/res/apk/topone.apk");
        toast(i18n.activiy("APK_DOWNLOAD_2"));
      }
    }
  }, [downloadData, platform]);

  useMount(() => {
    const ua = navigator.userAgent;
    if (isIOS(ua)) {
      setPlatform(PlatformType.Ios);
    } else {
      setPlatform(PlatformType.Android);
    }
  });

  const onClose = () => {
    setIsShowIosModal(false);
    setTimeout(() => {
      dispatch(
        ActivityActions.setAppDownload({
          isVisible: false,
        }),
      );
    }, 1000);
  };

  return (
    downloadData?.isVisible && (
      <>
        {platform === PlatformType.Ios ? (
          <div className="download-ios-modal">
            <div className="download-ios-backdrop"></div>
            <div
              className={classNames(
                `download-ios-content animate__animated ${isShowIosModal ? "slider-up" : "slider-down"}`,
              )}
            >
              <div className="close-btn">
                <Img src={closeIcon2} onClick={onClose} className="w-[.32rem] absolute top-[.3rem] right-[.3rem]" />
              </div>
              <LazyImage src={cdnPrefix("/download/ios-download-tips.png")} height="auto" />
            </div>
          </div>
        ) : (
          <div className="fixed z-[1007]"></div>
        )}
      </>
    )
  );
};

//下载按钮，用于一二级大厅的入口
export const AppDownloadButton = memo(() => {
  const dispatch = useDispatch();
  const appDownload = useAppSelector((state) => state.activitySlice.appDownload);

  useMount(() => {
    dispatch(
      ActivityActions.setAppDownload({
        buttonIsVisible: true,
      }),
    );
  });
  const onShowAppDownload = () => {
    dispatch(
      ActivityActions.setAppDownload({
        isVisible: true,
      }),
    );
  };

  const onClose = () => {
    valueset(globalThis, APP_DOWNLOAD_BUTTON_KEY, true);
    dispatch(
      ActivityActions.setAppDownload({
        buttonIsVisible: false,
      }),
    );
  };
  const cacheIsVisible = get(globalThis, APP_DOWNLOAD_BUTTON_KEY);
  return (
    appDownload?.buttonIsVisible &&
    !cacheIsVisible && (
      <>
        <div className="app-download-button-wrap max-w-[7.68rem]">
          <div className="relative">
            <div className="download-button" onClick={onShowAppDownload}>
              {i18n.activiy("APK_DOWNLOAD_1")}
            </div>
            <div className="close-btn absolute -right-2 -top-1">
              <Img src={closeIcon} width=".3rem" onClick={onClose} />
            </div>
          </div>
        </div>
      </>
    )
  );
});

AppDownloadButton.displayName = "AppDownloadButton";

export default AppDownload;
