import { CmdToPbName } from "@/utils/net/INet";
import { agent } from "@/utils/net/shared/proto";

export * from "./user";
export { default as useActivityHooks } from "./activity";
export { default as useAgentHooks } from "./agent";
export { default as useAssetHooks } from "./assets";
export { default as useEmailHooks } from "./email";
export { default as useHallHooks } from "./hall";
export { default as useMyBonusHooks } from "./my-bonus";
export { default as useRankHooks } from "./rank";
export { default as useRenverHooks } from "./renver";
export { default as useSpinHooks } from "./spin";
export { default as useTeamHooks } from "./team";
export { default as useThirdGameHooks } from "./third-game";
export { default as useVipHooks } from "./vip";
export { default as useWalletHooks } from "./wallet";
export { default as useWheelHooks } from "./wheel";
export { default as useGoodsHooks } from "./goods";
export { default as useLogReport } from "./useLogReport";
export { default as useExchangeCodeHooks } from "./exchange-code";
export { default as useAgentRankHooks } from "./agentRank";
export { default as useAreaConfig } from "./areaConfig";
// 心跳返回
CmdToPbName[agent.AGENT_CMD.AGENT_CMD_HEART_BEAT] = "agent.HeartBeat";
