"use client";

import AlertBody from "@/components/Alert/AlertBody";
import Image from "next/image";
import { Alert, AlertHeader } from "@/components/Alert";
import { AuthActions } from "@/redux/features/auth-slice";
import { cdnPrefix, i18n } from "@/utils";
import { useAppSelector } from "@/redux/store";
import { useDispatch } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import { UserService } from "@/service";
import "./AvatarAlert.scss";
import Img from "@/components/Img";
import { StorageData } from "@/utils/net/storage/StorageData";
import { StoreKey } from "@/const";
import { Button } from "@/components/Button";
import toast from "../Toast";

const avatarList = () => {
  const result = [];
  for (let i = 1; i < 10; i++) {
    if (i === 6) {
      continue;
    }
    result.push({
      user_avatar: i.toString(),
      src: `/profile/g_tx0${i}.jpg`,
    });
  }
  return result;
};

function AvatarAlert() {
  const show_profile_alert = useAppSelector((state) => state.authSlice.show_profile_alert);
  const user_base_info = useAppSelector((state) => state.authSlice.basic_info);

  const dispatch = useDispatch();
  const [avatarSelect, setAvatarSelect] = useState("1");

  const lists = useMemo(() => {
    return avatarList();
  }, []);

  const onSaveAvatar = () => {
    StorageData.local.set(StoreKey.SET_INFO_TYPE, "avatar");
    UserService.reqSetUserInfo({
      user_avatar: avatarSelect,
    });
    toast(`${i18n.common("sameless_1")}`);
    // dispatch(AuthActions.setAvatarAlert(false));
  };

  const onClickAvatar = (avatar: string) => (e: React.MouseEvent) => {
    e.stopPropagation();
    setAvatarSelect(avatar);
  };

  useEffect(() => {
    if (show_profile_alert?.avatar) {
      setAvatarSelect(user_base_info?.user_avatar || "1");
    }
  }, [show_profile_alert?.avatar, user_base_info?.user_avatar]);

  return (
    <Alert
      open={show_profile_alert?.avatar}
      onClose={() => {
        dispatch(AuthActions.setAvatarAlert(false));
      }}
    >
      <AlertHeader title="Avatar" />
      <AlertBody>
        <div className="grid grid-cols-4 gap-[0.25rem]">
          {lists.map((item) => {
            return (
              <div
                className="avatar-item avatar-border relative"
                key={item.user_avatar}
                onClick={onClickAvatar(item.user_avatar)}
              >
                <Img
                  src={item.src}
                  alt={item.user_avatar}
                  width={120}
                  height={120}
                  className="max-w-[1.2rem] max-h-[1.2rem] rounded-[50%]"
                />
                {avatarSelect === item.user_avatar && (
                  <Img
                    src={cdnPrefix("profile/g_ytxxz.png")}
                    alt="g_ytxxz.png"
                    width={39}
                    height={39}
                    className="max-w-[0.39rem] max-h-[0.39rem] absolute right-0 bottom-0"
                  />
                )}
              </div>
            );
          })}
        </div>
        <div className="flex justify-center">
          {/* <div className="avatar-salvar-button" onClick={onSaveAvatar}>
            Salvar
          </div> */}
          <Button className="!mt-[0.91rem] w-[2.73rem]" onClick={() => setTimeout(onSaveAvatar, 200)}>
            {i18n.common("LYSETTING_43")}
          </Button>
        </div>
      </AlertBody>
    </Alert>
  );
}

export default AvatarAlert;
