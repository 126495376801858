import { StoreKey } from "@/const";
import { useAppSelector } from "@/redux/store";
import { UserService } from "@/service";
import { SDK } from "@/utils";
import { StorageData } from "@/utils/net/storage/StorageData";
import { usePathname, useSearchParams } from "next/navigation";
import { memo, useEffect } from "react";

const AFReport = memo(() => {
  const searchParams = useSearchParams();
  const user_basic_info = useAppSelector((state) => state.authSlice.basic_info);
  const loginType = useAppSelector((state) => state.authSlice.loginType);
  const pathname = usePathname();

  useEffect(() => {
    const invite_code = searchParams.get("ic");
    const oldInviteCode = StorageData.local.get(StoreKey.INVITE_CODE);
    const isLoginPage = pathname === "/login";

    if (invite_code) {
      StorageData.local.set(StoreKey.INVITE_CODE, invite_code);
    }

    const useInviteCode = invite_code || oldInviteCode;

    if (!isLoginPage && loginType !== 0 && loginType !== 1) {
      const param: any = {
        channel: "web",
        ua: window.navigator.userAgent,
        device_id: SDK.device(),
        invite_code: JSON.stringify({
          ic: useInviteCode,
        }),
        af_id: "kb-" + user_basic_info?.uid!,
      };
      UserService.AFReport(param as any);
    }
  }, [searchParams, user_basic_info, pathname]);

  return <></>;
});

AFReport.displayName = "AFReport";

export default AFReport;
