import AlertBody from "@/components/Alert/AlertBody";
import { Alert, AlertHeader } from "@/components/Alert";
import { AuthActions } from "@/redux/features/auth-slice";
import { cdnPrefix, i18n } from "@/utils";
import { ChangeEvent, useMemo } from "react";
import { client_proto } from "@/utils/net/shared/proto";
import { useAppSelector } from "@/redux/store";
import { useDispatch } from "react-redux";
import { UserService } from "@/service";
import { useSetState, useUpdateEffect } from "react-use";
import "./GendersAlert.scss";
import Img from "@/components/Img";
import { LazyImage } from "@/components/LazyloadImage";
import { Button } from "@/components/Button";
import ICON_NAN from "@/assets/profile/xz_nan.inline.png";
import ICON_NV from "@/assets/profile/xz_nv.inline.png";
import toast from "../Toast";

function GendersAlert() {
  const dispatch = useDispatch();

  const basic_info = useAppSelector((state) => state.authSlice.basic_info);
  const show_profile_alert = useAppSelector((state) => state.authSlice.show_profile_alert);
  const [form, setFrom] = useSetState<client_proto.ISetUserInfoReq>({});

  const isMan = useMemo(() => form?.user_gender === "1", [form?.user_gender]);

  const gender = (isMan: boolean) => () => {
    setFrom({
      user_gender: isMan ? "1" : "2",
    });
  };

  const onSubmit = () => {
    if (!form?.user_name) {
      toast(`${i18n.common("LYSETTING_0")}`);
      return;
    }
    // 判断username是否含有非法字符
    if (form?.user_name && !/^[a-zA-Z0-9\u4e00-\u9fa5]+$/.test(form?.user_name)) {
      toast(`${i18n.common("sameless_2")}`);
      return;
    }
    UserService.reqSetUserInfo(form);
    toast(`${i18n.common("sameless_1")}`);
    dispatch(AuthActions.setGendersAlert(false));
  };

  useUpdateEffect(() => {
    setFrom({
      user_name: basic_info?.user_name,
      user_gender: String(basic_info?.user_gender),
    });
  }, [show_profile_alert?.genders]);

  return (
    <Alert
      open={show_profile_alert?.genders}
      onClose={() => {
        dispatch(AuthActions.setGendersAlert(false));
      }}
    >
      <AlertHeader title={`${i18n.home("MODIFY")}`} />
      <AlertBody>
        <div className="genders-alert text-white">
          <div className="grid gap-[0.5rem] grid-cols-[1.23rem,1fr] items-center mt-[0.8rem]">
            <label>{i18n.home("LYSETTING_40")}:</label>
            <div className="gender-input">
              <input
                value={form?.user_name ?? ""}
                maxLength={15}
                placeholder={i18n.home("UPDATE_NICKNAME")}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  const filteredValue = value.replace(/[\u4e00-\u9fa5]/g, "");
                  setFrom({ user_name: filteredValue });
                }}
              />
            </div>
          </div>
          <div className="grid gap-[0.5rem] grid-cols-[1.23rem,1fr]  items-center mt-[0.31rem]">
            <label>{i18n.home("LYSETTING_41")}：</label>
            <div className="flex items-center justify-between gap-[0.31rem]">
              <div
                className={`man-select ${isMan ? "man-select-active" : ""} w-[1.85rem] h-[0.54rem] flex items-center justify-center gap-[0.1rem] btn_animation`}
                onClick={gender(true)}
              >
                <div className="sex-img">
                  <LazyImage src={ICON_NAN as unknown as string} height={"100%"} className="w-[0.28rem]" />
                </div>
                <span>{i18n.login("LYSETTING_2")}</span>
              </div>

              <div
                className={`woman-select ${!isMan ? "woman-select-active" : ""} w-[1.85rem] h-[0.54rem] flex items-center justify-center gap-[0.1rem] btn_animation`}
                onClick={gender(false)}
              >
                <div className="sex-img">
                  <LazyImage src={ICON_NV as unknown as string} height={"100%"} className="w-[0.28rem]" />
                </div>
                <p>{i18n.login("LYSETTING_3")}</p>
              </div>
            </div>
          </div>
        </div>
        <Button
          className="modal-btn-red w-[2.73rem] !mt-[0.91rem] absolute left-0 right-0"
          onClick={() => {
            setTimeout(() => {
              onSubmit();
            }, 500);
          }}
        >
          {i18n.common("LYSETTING_43")}
        </Button>
      </AlertBody>
    </Alert>
  );
}

export default GendersAlert;
