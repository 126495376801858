import Img from "@/components/Img";
import { cdnPrefix } from "@/utils";
import { useContext, useEffect } from "react";
import SettingContext from "@/service/setting";
import Link from "next/link";
import { StorageData } from "@/utils/net/storage/StorageData";
import { StoreKey } from "@/const";
import { AgentRankService } from "@/service/agent-rank";
import useAgentRankResp from "@/app/earn/components/invitationRewards/useAgentRankResp";
import { usePathname } from "next/navigation";

function ActivityButton() {
  const { agentId } = useAgentRankResp();
  const { settings, setSettings } = useContext(SettingContext);
  const pathname = usePathname();

  const showPathNames = ["/", "/ranking", "/turntable", "/vip", "/gameList"];

  const onClick = () => {
    setTimeout(() => {
      StorageData.local.set(StoreKey.SHOW_ACTIVITY_BUTTON, JSON.stringify({ [agentId!]: false }));
      setSettings?.({
        ...settings,
        showActivityButton: false,
      });
    }, 500);
  };

  useEffect(() => {
    AgentRankService.getRankShowListResp();
  }, []);

  useEffect(() => {
    if (agentId && agentId > 0) {
      const dbStr = StorageData.local.get(StoreKey.SHOW_ACTIVITY_BUTTON) as string;
      if (dbStr) {
        const dbJson = dbStr ? JSON.parse(dbStr!) : {};
        const key = Number(Object.keys(dbJson)[0]);

        if (key && key !== agentId) {
          // 开启了新一轮活动
          StorageData.local.set(StoreKey.SHOW_ACTIVITY_BUTTON, JSON.stringify({ [agentId]: true }));
          setSettings?.({
            ...settings,
            showActivityButton: true,
          });
        } else {
          const current = dbJson[agentId];
          setSettings?.({
            ...settings,
            showActivityButton: current,
          });
        }
      } else {
        StorageData.local.set(StoreKey.SHOW_ACTIVITY_BUTTON, JSON.stringify({ [agentId]: true }));
        setSettings?.({
          ...settings,
          showActivityButton: false,
        });
      }
    } else {
      StorageData.local.remove(StoreKey.SHOW_ACTIVITY_BUTTON);
      setSettings?.({
        ...settings,
        showActivityButton: false,
      });
    }
  }, [agentId, setSettings, settings]);

  return (
    showPathNames.includes(pathname) &&
    settings?.showActivityButton && (
      <div className={"fixed bottom-[1.8rem] right-[0.16rem] z-[999]"}>
        <Link replace={true} href={"/earn?id=2"} prefetch onClick={onClick}>
          <Img noLoad src={cdnPrefix("/earn/ranking/Activity_button.png")} className={"w-[1.5rem]"} />
        </Link>
      </div>
    )
  );
}

export default ActivityButton;
