import { useCallback, useContext, useMemo } from "react";
import SettingContext from "@/service/setting";

export default function useAreaConfig() {
  const { settings } = useContext(SettingContext);

  const getPhoneAreaLength = useCallback(() => {
    let phoneLength;
    if (settings.language === "pt") {
      phoneLength = 11;
    } else phoneLength = 10;
    return phoneLength;
  }, [settings?.language]);

  return {
    getPhoneAreaLength,
  };
}
