import { Goods } from "@/const";
import { GoodsActions } from "@/redux/features/goods-slice";
import { EventGlobalManager } from "@/utils/events";
import { useDispatch } from "react-redux";

export default function useGoodsHooks() {
  const dispatch = useDispatch();

  function onListenSocket() {
    EventGlobalManager.on(Goods.Resp.RULES, (rsp: any) => {
      const { data } = rsp;
      dispatch(GoodsActions.setRules(data));
    });
    EventGlobalManager.on(Goods.Push.GetPropPush, (rsp: any) => {
      const { data } = rsp;
      dispatch(GoodsActions.setPropPush(data));
    });
  }
  return {
    onListenSocket,
  };
}
